import { useQuery } from '@tanstack/react-query';
import { Club } from '@/lib/clubs/contracts/club.ts';
import { getClubs } from '@/lib/clubs';
import { useMemo } from 'react';
import { useMember } from '@/lib/members/hooks/useMember.ts';

export default function useClubs() {
  const { member } = useMember();
  const { data: clubs, isLoading } = useQuery<Club[]>({
    queryKey: ['clubs'],
    queryFn: async () => await getClubs(),
    enabled: !!member,
  });
  const currentClub: Club | null = useMemo(() => {
    if (!clubs) {
      return null;
    }
    return clubs.find((club) => +club.id === +(member?.clubId ?? 0)) ?? null;
  }, [clubs, member?.clubId]);

  return {
    isLoading,
    clubs,
    currentClub,
  };
}
