import { createFileRoute, useNavigate } from '@tanstack/react-router';
import Loading from '@/components/common/loading.tsx';
import { useEffect } from 'react';
import { useAuthState } from '@/lib/auth/hooks/useAuthState.ts';

/**
 * This route is used to log the user out.
 * It just shows a loading spinner while the logout is in progress.
 */
export const Route = createFileRoute('/logout')({
  component: () => <LogoutRoute />,
});

function LogoutRoute() {
  const { isAuthenticated, cleanSession } = useAuthState();
  const navigate = useNavigate();

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    const checkAuth = async () => {
      console.debug('Performing logout...');

      // If not authenticated, redirect to home
      if (!isAuthenticated) {
        console.debug('Not authenticated. Redirecting to home...');

        // Await for Auth0 state to be updated
        timeout = setTimeout(() => {
          window.location.replace('/');
        }, 500);
        return;
      }

      // Clean the session. This will log the user out and redirect to the home page
      await cleanSession();
    };

    checkAuth();

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [isAuthenticated, navigate, cleanSession]);

  return <Loading />;
}
