import { AxiosError } from 'axios';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import { toast } from 'sonner';

/**
 * Handle server errors and display them in the form.
 * @param form
 * @param exception
 * @param fieldsMap. Sometimes the server returns errors with keys that don't match the form fields.
 * This object maps server field names to form field names.
 */
export function handleServerErrors<T extends FieldValues>(
  form: UseFormReturn<T>,
  exception: AxiosError | unknown,
  fieldsMap?: { [key: string]: string },
) {
  // If instance is not an AxiosError, it's not a server error, so we throw it
  if (!(exception instanceof AxiosError)) {
    console.warn('Unknown error', exception);
    throw exception;
  }

  if (exception.response?.status === 422) {
    const serverErrors: Record<string, string[]> = exception.response.data?.errors ?? {};

    console.debug('Handling server errors', serverErrors);

    // If we have no errors, display a generic error
    if (Object.keys(serverErrors).length === 0) {
      console.warn('An empty error object was returned from the server');
      toast.error('An error occurred');
      return;
    }

    const fieldNames = Object.keys(form.getValues());

    Object.keys(serverErrors).forEach((key) => {
      const message = (serverErrors[key] ?? ['Error']).join('. ');

      // Map the server key to form field name
      const mappedKey = fieldsMap?.[key] ?? key;

      // If key is not present in form, display toast
      if (!fieldNames.includes(mappedKey)) {
        console.warn(`Field ${mappedKey} not found in form`);
        toast.error(message);
        return;
      }

      form.setError(mappedKey as 'root', {
        type: 'server',
        message,
      });
    });
  } else {
    console.error(exception);
    toast.error('Ha ocurrido un error. Por favor, inténtelo de nuevo más tarde.');
  }
}
