import { NavList } from '@/components/common/nav/types';
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
} from '@/components/ui/navigationMenu';
import Separator from '@/components/ui/separator';
import { cn } from '@/lib/utils';
import { Link } from '@tanstack/react-router';

export default function Nav({ items, className }: { items: NavList[]; className?: string }) {
  return (
    <>
      <nav
        className={cn(
          'hidden list-none items-end justify-between space-y-2 md:flex md:flex-col md:items-start md:justify-start',
          className,
        )}
      >
        {items.map(({ link, label, icon }, index) => (
          <li key={index} className="flex w-full">
            <Link
              activeProps={{ className: 'bg-muted' }}
              activeOptions={{ exact: true }}
              to={link}
              className="flex h-10 w-full flex-row items-center space-x-2 text-nowrap rounded-sm py-2 pl-4 text-sm transition-all duration-200 ease-in-out hover:text-secondary-hover md:text-base"
            >
              {!!icon && icon}
              <span>{label}</span>
            </Link>
          </li>
        ))}
      </nav>
      <NavigationMenu className="mt-2 block md:hidden">
        <NavigationMenuList>
          <NavigationMenuItem>
            <NavigationMenuTrigger>Menu</NavigationMenuTrigger>
            <NavigationMenuContent className="list-none">
              {items.map(({ link, label }, index) => (
                <NavigationMenuItem key={index} className="py-1">
                  <Link
                    activeProps={{ className: 'bg-muted' }}
                    to={link}
                    className="block text-nowrap px-4 py-2 text-sm transition-all duration-200 ease-in-out hover:text-secondary-hover"
                  >
                    <NavigationMenuLink>{label}</NavigationMenuLink>
                  </Link>
                </NavigationMenuItem>
              ))}
            </NavigationMenuContent>
          </NavigationMenuItem>
        </NavigationMenuList>
      </NavigationMenu>

      <Separator orientation="horizontal" className="block md:hidden" />
    </>
  );
}
