import { createFileRoute } from '@tanstack/react-router';
import { useEffect } from 'react';
import Loading from '@/components/common/loading.tsx';
import { useAuthState } from '@/lib/auth/hooks/useAuthState.ts';
import { z } from 'zod';

const searchSchema = z.object({
  redirect: z.string().optional(),
});

export const Route = createFileRoute('/login')({
  validateSearch: searchSchema,
  component: () => <Login />,
});

function Login() {
  const { redirect } = Route.useSearch();
  const { login, signup } = useAuthState();

  // If we're redirecting to join page, we assume the user is new, so we'll show the signup hint
  const isSignup = redirect?.includes('/asociate');

  useEffect(() => {
    console.debug('Redirecting to login...');

    if (isSignup) {
      signup({
        returnTo: redirect,
      });

      return;
    }

    login({
      returnTo: redirect,
    });
  }, [isSignup, login, redirect, signup]);

  return <Loading />;
}
