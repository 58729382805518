import { FC } from 'react';
import { cn } from '@/lib/utils';
import { LabelType } from '@/components/ui/header/header.types';
import { Button } from '@/components/ui/button';
import { useNavigate } from '@tanstack/react-router';

type navItemProps<T extends LabelType> = {
  label: T;
  link?: string;
  className?: string;
  onClick?: () => void;
};

export const desktop = 'lg:border-0 lg:p-0 lg:w-fit lg:px-2 hover:text-primary';
export const mobile = 'px-[5%] py-[10px] border-b border-border w-full ';

const NavItem: FC<navItemProps<LabelType>> = ({ label, link, className, onClick }: navItemProps<LabelType>) => {
  const navigate = useNavigate()

  const clickHandler = () => {
    navigate({ to: link })
    if (onClick) {
      onClick();
    }
  }

  return (
    <li
      className={cn('cursor-pointer pr-5 text-sm font-bold text-stone-500 transition-all duration-500 ease-in-out', [
        mobile,
        desktop,
        className,
      ])}
    >
      {link ? <Button variant={'link'} onClick={clickHandler}>{label}</Button> : label}
    </li>
  );
};

export default NavItem;
