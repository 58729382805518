export const API_URL = import.meta.env.VITE_API_URL;
export const AUTH0_DOMAIN = import.meta.env.VITE_AUTH0_DOMAIN;
export const AUTH0_CLIENT_ID = import.meta.env.VITE_AUTH0_CLIENT_ID;
export const AUTH0_AUDIENCE = import.meta.env.VITE_AUTH0_AUDIENCE;
export const AUTH0_LOGOUT_REDIRECT_URI = import.meta.env.VITE_AUTH0_LOGOUT_REDIRECT_URI;
export const CINEPOLIS_API_URL = import.meta.env.VITE_CINEPOLIS_API_URL;
export const CLUB_SANTANDER_ID = import.meta.env.VITE_CLUB_SANTANDER_ID;
export const CLUB_LA_NACION_ID = import.meta.env.VITE_CLUB_LA_NACION_ID;
export const CLUB_RIO_NEGRO_ID = import.meta.env.VITE_CLUB_RIO_NEGRO_ID;
export const CLUB_MOVIECLUB_ID = import.meta.env.VITE_CLUB_MOVIECLUB_ID;
export const CLUB_FUNESCOOP_ID = import.meta.env.VITE_CLUB_FUNESCOOP_ID ?? 8;
export const CLUB_FUNESCOOP_EMPLEADOS_ID = import.meta.env.VITE_CLUB_FUNESCOOP_EMPLEADOS_ID ?? 9;
export const CLUB_LA_MAQUINITA_ID = import.meta.env.VITE_CLUB_LA_MAQUINITA_ID ?? 19;
export const CLUB_MOVISTAR_ID = import.meta.env.VITE_CLUB_MOVISTAR_ID ?? 21;
export const DEFAULT_CLUB_ID = import.meta.env.VITE_DEFAULT_CLUB_ID;
export const VISTA_TICKETING_URL = import.meta.env.VITE_VISTA_TICKETING_URL;
export const CONTESTS_URL = import.meta.env.VITE_CONTESTS_URL ?? 'https://promos.movieclub.com.ar/concursos';
export const TERMS_AND_CONDITIONS =
  import.meta.env.VITE_TERMS_AND_CONDITIONS ?? 'https://promos.movieclub.com.ar/terminos-y-condiciones';
export const BASES_AND_CONDITIONS =
  import.meta.env.VITE_BASES_AND_CONDITIONS ?? 'https://promos.movieclub.com.ar/bases-y-condiciones';
export const GA_MEASUREMENT_ID = import.meta.env.VITE_GA_MEASUREMENT_ID ?? 'G-5NW20L41PX';
export const GOOGLE_RECAPTCHA_SITE_KEY = import.meta.env.VITE_GOOGLE_RECAPTCHA_SITE_KEY;
