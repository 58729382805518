import { cn } from '@/lib/utils';
import { FC, ReactElement } from 'react';

type loadingIndicatorProps = {
    className?: string,
};

const LoadingIndicator: FC<loadingIndicatorProps> = ({ className }: loadingIndicatorProps): ReactElement => {
    return <div className={cn("border-white h-5 w-5 animate-spin rounded-full border-2 border-t-primary", className)} />
};

export default LoadingIndicator;
