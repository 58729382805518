import { useMemo, useState } from 'react';
import './signup-stepper.css';
import { useNavigate } from '@tanstack/react-router';
import { CheckIcon } from '@radix-ui/react-icons';
import { Skeleton } from '@/components/ui/skeleton.tsx';

export type SignUpStepperProps = {
  active?: number;
  clubId?: string | number;
};

const STEPS: {
  title: string;
  description: string;
  to?: string;
}[] = [
  {
    title: 'Registro',
    description: 'Regístrate',
  },
  {
    title: 'Datos Personales',
    description: 'Completa tus datos personales',
  },
  {
    title: 'Medio de Pago',
    description: 'Agrega o tu medio de pago',
  },
];

export default function SignUpStepper({ active = 1 }: SignUpStepperProps) {
  const [currentStep] = useState(active);
  const [complete] = useState(false);
  const navigate = useNavigate();

  // TODO: Filter steps based on club requirements
  const steps = useMemo(() => {
    return STEPS;
  }, []);

  const isLoading = false;

  return (
    <>
      {isLoading && (
        <div className="mx-auto flex w-full max-w-2xl items-center space-x-2">
          <Skeleton className="h-10 w-10 rounded-full" />
          <Skeleton className="h-2 flex-1" />
          <Skeleton className="h-10 w-10 rounded-full" />
          <Skeleton className="h-2 flex-1" />
          <Skeleton className="h-10 w-10 rounded-full" />
        </div>
      )}

      {!isLoading && (
        <div className="flex items-start">
          {steps?.map((step, i) => (
            <div
              key={i}
              className={`step-item ${currentStep === i + 1 && 'active'} ${
                (i + 1 < currentStep || complete) && 'complete'
              } `}
              onClick={() => {
                if ((i + 1 < currentStep || complete) && step.to) {
                  navigate({ to: step.to, replace: true });
                }
              }}
            >
              <div className="step">{i + 1 < currentStep || complete ? <CheckIcon className="h-6 w-6" /> : i + 1}</div>
              <p className="mt-2 text-center text-sm text-gray-500 lg:text-base">{step.title}</p>
            </div>
          ))}
        </div>
      )}
    </>
  );
}
