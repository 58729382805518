import client from '@/lib/cinepolis/client.ts';
import { Complex } from '@/lib/cinepolis/complexes/contracts.ts';

export async function index(params = undefined): Promise<Complex[]> {
  const response = await client.get<Complex[]>('complexes', { params });
  return response.data;
}

export async function show(id: number, params = undefined): Promise<Complex> {
  const response = await client.get<Complex>(`complexes/${id}`, { params });
  return response.data;
}

export async function messages(id: number, params = undefined) {
  const response = await client.get(`complexes/${id}/messages`, { params });
  return response.data;
}
