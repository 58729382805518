import { find } from 'lodash';
import { ShowtimeOption, ShowtimeShowtime } from '@/lib/cinepolis/showtimes/contracts.ts';
import moment from 'moment';

export default {
  formatStartsAt(showtime: ShowtimeShowtime): string {
    const startsAt = moment(showtime.starts_at, 'YYYY-MM-DD HH:mm:ss');
    return startsAt.format('HH:mm');
  },

  // TODO: Make dynamic
  link(showtime: ShowtimeShowtime): string {
    return `https://cinepolis.com.ar/funciones/${showtime.external_id.split('-').join('/')}`;
  },

  showtimeClass(showtime: ShowtimeShowtime) {
    if (showtime.seats_percent > 85) {
      return 'bg-red-500 hover:bg-red-600';
    } else if (showtime.seats_percent > 50) {
      return 'bg-yellow-500 hover:bg-yellow-600';
    }
    return 'bg-green-500 hover:bg-green-600';
  },

  showtimeTooltip(showtime: ShowtimeShowtime): string {
    const startsAt = moment(showtime.starts_at, 'YYYY-MM-DD HH:mm:ss');
    const midnight = startsAt.clone().startOf('day');
    const morning = midnight.clone().add(6, 'hours');

    let use = '';

    if (showtime.seats_percent > 85) {
      use = 'Ocupación ' + showtime.seats_percent + '%';
    } else if (showtime.seats_percent > 50) {
      use =
        'La sala se está llenando. ¡Apurate, comprá tu entrada y asegurá tu lugar! Ocupación: ' +
        showtime.seats_percent +
        '%';
    }

    if (startsAt.isBetween(midnight, morning)) {
      use += ' TRANSNOCHE';
    }

    return use.trim();
  },

  requiresCapacityInfo(showtimes: ShowtimeOption[], threshold: number): boolean {
    return !!find(showtimes, (info) => {
      return !!find(info.showtimes, (showtime) => {
        return showtime.seats_percent >= threshold;
      });
    });
  },
};
