export const Brands = {
  SANTANDER: 'santander',
  CLUB_LA_NACION: 'club-la-nacion',
  CLUB_RIO_NEGRO: 'club-rio-negro',
  MOVIECLUB: 'movieclub',
  FUNESCOOP: 'funescoop',
  LA_MAQUINITA: 'la-maquinita-co',
  CLUB_MOVISTAR: 'club-movistar',
  FUNESCOOP_EMPLEADOS: 'funescoop-empleados',
};
