import { ShowtimeOption, ShowtimeShowtime } from '@/lib/cinepolis/showtimes/contracts.ts';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog.tsx';
import { useQuery } from '@tanstack/react-query';
import { show as getComplex } from '@/lib/cinepolis/complexes/api.ts';
import { messages as getMovieMessages, show as getMovie } from '@/lib/cinepolis/movies/api.ts';
import { Dates } from '@/lib/cinepolis/Dates.ts';
import Showtimes from '@/lib/cinepolis/Showtimes.ts';
import LoadingIndicator from '@/components/ui/loadingIndicator.tsx';
import React, { useState } from 'react';
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert.tsx';
import { cn } from '@/lib/utils.ts';
import { CircleAlertIcon, CircleCheckIcon, CircleXIcon, InfoIcon, Loader2 } from 'lucide-react';
import { Button } from '@/components/ui/button.tsx';
import { getUserSessionId } from '@/lib/members';
import { VISTA_TICKETING_URL } from '@/lib/config.ts';
import { useMember } from '@/lib/members/hooks/useMember.ts';

export type ShowtimeModalProps = {
  open: boolean;
  movieId?: number;
  complexId?: number;
  showtime?: ShowtimeShowtime | null;
  options?: ShowtimeOption[];
  onOpenChange: (open: boolean) => void;
};

// TODO: Implement all attributes
export default function ShowtimeModal({ open, movieId, complexId, showtime, onOpenChange }: ShowtimeModalProps) {
  const [isGeneratingTicketingLink, setIsGeneratingTicketingLink] = useState(false);
  const { member, isLoading: isLoadingMember } = useMember();

  const { data: complex, isPending: isComplexPending } = useQuery({
    queryKey: ['complexes', complexId],
    queryFn: async () => (complexId ? await getComplex(complexId) : undefined),
    enabled: !!complexId,
  });

  const { data: movie, isPending: isMoviePending } = useQuery({
    queryKey: ['movies', movieId],
    queryFn: async () => (movieId ? await getMovie(movieId) : undefined),
    enabled: !!movieId,
  });

  const { data: messages } = useQuery({
    queryKey: ['movies', movieId, 'messages'],
    queryFn: async () => (movieId ? await getMovieMessages(movieId) : undefined),
    enabled: !!movieId,
  });

  const isLoading = isComplexPending || isMoviePending || isLoadingMember;

  // Time
  const dayName = showtime ? Dates.name(showtime.starts_at) : '';
  const hours = showtime ? Showtimes.formatStartsAt(showtime) : '';
  const startsAt = `${dayName} a las ${hours}hs.`;

  // Ocupación de la sala
  const hasCapacity = true;

  // Link
  // const link = showtime ? Showtimes.link(showtime) : '';

  // Attributes
  // const has4D = options?.screen === '4d';
  // const has3D = options?.format === '3D';

  // TODO: Implementar
  // this.hasAmigable = !!find(this.movieAttributes, ['value', 'FUNCIÓN AMIGABLE'])*/

  // const hasDescriptiva = !!find(options?.attributes, (o) => o === 'DESCRIP');

  const navigateToTicketing = async (showtime: ShowtimeShowtime): Promise<void> => {
    setIsGeneratingTicketingLink(true);

    try {
      const [cinemacode, txtSessionId] = showtime.external_id.split('-');
      const userSessionId: string | undefined = member?.MemberId ? await getUserSessionId() : undefined;

      const url = new URL(VISTA_TICKETING_URL);
      url.searchParams.set('cinemacode', cinemacode);
      url.searchParams.set('txtSessionId', txtSessionId);

      // Redirect using GET
      if (userSessionId) {
        url.searchParams.set('UserSessionId', userSessionId);
      }
      window.location.replace(url.toString());

      // Redirect using POST
      // Add UserSessionId as a hidden input
      // const form = document.createElement('form');
      // form.method = 'POST';
      // form.action = url.toString();
      // if (userSessionId) {
      //   const input = document.createElement('input');
      //   input.type = 'hidden';
      //   input.name = 'UserSessionId';
      //   input.value = userSessionId;
      //   form.appendChild(input);
      // }
      // document.body.appendChild(form);
      // form.submit();
    } finally {
      setIsGeneratingTicketingLink(false);
    }
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Confirme su elección</DialogTitle>
          <DialogDescription>Lea atentamente la información antes de continuar.</DialogDescription>
        </DialogHeader>
        <div className="p-4">
          {isLoading && (
            <div className="flex flex-row justify-center p-6">
              <LoadingIndicator />
            </div>
          )}
          {!isLoading && !!movie && !!showtime && (
            <div className="flex flex-col space-y-4">
              <ul>
                <li>
                  <strong>Película</strong>: <span>{movie?.title_translated}</span>
                </li>
                <li>
                  <strong>Complejo</strong>: <span className="text-primary">{complex?.long_name ?? complex?.name}</span>
                </li>
                <li>
                  <strong>Función</strong>: <span className="text-primary first-letter:uppercase">{startsAt}</span>
                </li>
                {!!movie?.parental_guide && (
                  <li>
                    <strong>Clasificación</strong>: <span className="uppercase">{movie.parental_guide}</span>
                  </li>
                )}
              </ul>
              {!!messages && messages.length > 0 && (
                <div className="flex flex-col space-y-2">
                  {React.Children.toArray(
                    messages?.map((message) => (
                      <Alert
                        className={cn({
                          'border-blue-800 bg-blue-100 text-blue-800': message.type === 'info',
                          'border-green-800 bg-green-100 text-green-800': message.type === 'success',
                          'border-yellow-800 bg-yellow-100 text-yellow-800': message.type === 'warning',
                          'border-red-800 bg-red-100 text-red-800': message.type === 'error',
                        })}
                      >
                        {/*<Terminal className="h-4 w-4" />*/}
                        {message.type === 'info' && <InfoIcon className="current- h-4 w-4" />}
                        {message.type === 'success' && <CircleCheckIcon className="h-4 w-4" />}
                        {message.type === 'warning' && <CircleAlertIcon className="h-4 w-4" />}
                        {message.type === 'error' && <CircleXIcon className="h-4 w-4" />}
                        <AlertTitle>{message.title}</AlertTitle>
                        <AlertDescription>{message.message}</AlertDescription>
                      </Alert>
                    )),
                  )}
                </div>
              )}

              {!hasCapacity && (
                <Alert variant="destructive">
                  <AlertDescription>
                    <p>
                      La función seleccionada se encuentra completa.
                      <br />
                      Por favor, Selecciona otra.
                    </p>
                  </AlertDescription>
                </Alert>
              )}
            </div>
          )}
        </div>
        <DialogFooter>
          {!!showtime && (
            <Button
              type="button"
              onClick={() => navigateToTicketing(showtime)}
              disabled={isLoading || isGeneratingTicketingLink}
            >
              {isGeneratingTicketingLink && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
              Continuar
            </Button>
          )}
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
