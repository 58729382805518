import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function formatCurrency(amount: number) {
  return amount.toLocaleString('es-ar', {
    style: 'currency',
    currency: 'ARS',
    minimumFractionDigits: 2,
  });
}

// export function currencyToNumber(entry: string) {
//   const numberString = entry.replace(/[^\d.,]/g, '').replace(',', '.');
//   return +numberString;
// }
