import { BugIcon } from 'lucide-react';
import { Button } from '@/components/ui/button.tsx';
import { useEffect } from 'react';
import Bugsnag from '@bugsnag/js';
import { useAuthState } from '@/lib/auth/hooks/useAuthState.ts';

type ErrorProps = {
  error: Error;
  notify?: boolean;
};

const ErrorComponent = ({ error, notify = true }: ErrorProps) => {
  const { isAuthenticated, logout } = useAuthState();

  // Notify Bugsnag
  useEffect(() => {
    if (notify) {
      Bugsnag.notify(error);
    }
  }, [error, notify]);

  return (
    <div className="container flex h-full flex-col justify-center">
      <div role="alert">
        <div className="flex flex-col items-center space-y-4">
          <BugIcon className="h-10 w-10" />
          <div className="space-y-2 text-center">
            <h1 className="text-lg font-bold">¡Algo salió mal!</h1>
            <p className="text-gray-500 dark:text-gray-400">
              Estamos trabajando para solucionar el problema. Por favor, intenta recargar la página.
              {`${window.location.href}`}
            </p>
            <p className="text-sm text-gray-500 dark:text-gray-400">
              Código de error: <code>{error.message}</code>
            </p>
          </div>
          <div className="flex gap-4">
            <Button variant="outline-primary" onClick={() => location.reload()}>
              Recargar
            </Button>
          </div>

          {isAuthenticated && (
            <div className="mt-4 flex gap-4">
              <Button className="text-sm" variant="link" onClick={() => logout()}>
                Cerrar sesión
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export { ErrorComponent };
export type { ErrorProps };
