import { FC, ReactElement } from 'react';
import { cn } from "@/lib/utils"

type separatorProps = {
    className?: string,
    orientation: "horizontal" | "vertical"
};

const Separator: FC<separatorProps> = ({ className, orientation }: separatorProps): ReactElement => {
    return (
        <div className={
            cn("shrink-0 bg-muted ",
                orientation === "horizontal" ? "h-[1px] w-full" : "h-full w-[1px]",
                className
            )}
        />
    )
};

export default Separator;
