import { createRouter } from '@tanstack/react-router';
import { routeTree } from '@/routeTree.gen.ts';

// Register the router instance for type safety
declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router;
  }
}

export interface IRouterContext {}

export const router = createRouter({
  routeTree,
  context: {},
});
