import { useLocalStorage } from 'usehooks-ts';
import { useAuth0 } from '@auth0/auth0-react';
import { registerRedirectState } from '@/lib/auth';
import { useCallback } from 'react';
import { useNavigate } from '@tanstack/react-router';

export type LoginParams = {
  returnTo?: string;
};

export type SignupParams = {
  returnTo?: string;
};

export type AuthState = {
  isLoading: boolean;
  isAuthenticated: boolean;
  accessToken: string | null;
  setAccessToken: (accessToken: string | null) => void;
  reset: () => void;
  login: (loginParams?: LoginParams) => Promise<void>;
  signup: (signupParams?: SignupParams) => Promise<void>;
  logout: () => Promise<void>;
  cleanSession: (params?: { message?: string }) => Promise<void>;
};

/**
 * This hook is responsible for getting and setting the current user and access token
 */
export function useAuthState(): AuthState {
  const [accessToken, setAccessToken] = useLocalStorage<string | null>('access_token', null);
  const { loginWithRedirect: auth0Login, logout: auth0Logout, isAuthenticated, isLoading } = useAuth0();
  const navigate = useNavigate();

  const reset = useCallback(() => {
    // Reset token
    setAccessToken(null);

    // Reset redirect state
    try {
      window.localStorage.removeItem('redirect_state');
    } catch (e) {
      console.error('Could not remove redirect state', e);
    }
  }, [setAccessToken]);

  const login = useCallback(
    async ({ returnTo }: SignupParams = {}) => {
      const redirectUri = returnTo ?? window.location.origin + '/miembros';

      console.debug('Logging in and redirecting to', redirectUri);

      await auth0Login({
        appState: redirectUri ? registerRedirectState(redirectUri) : undefined,
      });
    },
    [auth0Login],
  );

  const signup = useCallback(
    async ({ returnTo }: SignupParams = {}) => {
      const redirectUri = returnTo ?? window.location.origin;

      console.debug('Signing up and redirecting to', redirectUri);

      await auth0Login({
        authorizationParams: {
          screen_hint: 'signup',
        },
        appState: redirectUri ? registerRedirectState(redirectUri) : undefined,
      });
    },
    [auth0Login],
  );

  const cleanSession = useCallback(
    async ({ message }: { message?: string } = {}) => {
      console.debug('Cleaning session...');

      reset();

      // Always return to log out to complete the logout process
      // Ensure the logout URL is properly configured in Auth0
      await auth0Logout({
        logoutParams: {
          returnTo: `${window.location.origin}/logout` + (message ? `?message=${encodeURIComponent(message)}` : ''),
        },
      });
    },
    [auth0Logout, reset],
  );

  const logout = useCallback(async () => {
    console.debug('Logging out...');

    await navigate({
      to: `/logout`,
      replace: true,
    });
  }, [navigate]);

  return {
    isLoading,
    isAuthenticated: isAuthenticated && !!accessToken,
    accessToken,
    setAccessToken,
    reset,
    login,
    signup,
    logout,
    cleanSession,
  };
}
