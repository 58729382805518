import { createFileRoute, useNavigate } from '@tanstack/react-router'
import MemberProfileForm from '@/lib/members/components/profile-form.tsx'
import { toast } from 'sonner'
import { useMember } from '@/lib/members/hooks/useMember.ts'
import LoadingBar from '@/components/common/loading-bar.tsx'
import { useCallback } from 'react'
import { Helmet } from 'react-helmet-async'

export const Route = createFileRoute(
  '/_checkAuth/_layout/_authenticated/_member/_layout/miembros_/perfil',
)({
  component: EditProfile,
})

function EditProfile() {
  const { member, reload, isLoading } = useMember()
  const navigate = useNavigate()

  const onSubmitted = useCallback(async () => {
    await reload()
    toast.success('La información de tu cuenta fue actualizada con éxito.')
  }, [reload])

  if (isLoading) {
    return <LoadingBar />
  }

  return (
    <>
      <Helmet>
        <title>Perfil | MovieClub</title>
      </Helmet>
      <MemberProfileForm
        member={member ?? undefined}
        onSubmitted={onSubmitted}
        onUnsubscribed={async () => {
          await reload()
          await navigate({
            to: '/',
          })
          toast.success('Tu suscripción fue cancelada con éxito.')
        }}
      />
    </>
  )
}
