import { createFileRoute } from '@tanstack/react-router';
import { PaymentMethodForm } from '@/components/payment-method/payment-method-form.tsx';
import { useCallback } from 'react';
import { z } from 'zod';
import SignUpStepper from '@/lib/members/components/signup-stepper.tsx';
import { Helmet } from 'react-helmet-async';

const paymentMethodsSearchSchema = z.object({
  redirect: z.string().optional(),
});

export const Route = createFileRoute('/_checkAuth/_layout/_authenticated/asociate_/medio-de-pago')({
  validateSearch: paymentMethodsSearchSchema,
  component: () => <PaymentMethodsPage />,
});

function PaymentMethodsPage() {
  const { redirect } = Route.useSearch();
  const navigate = Route.useNavigate();

  // Wait for user data to load. If user is already a member, redirect to the dashboard
  // useEffect(() => {
  //   const check = async () => {
  //     if (member?.MemberId) {
  //       console.debug('User is already a member, redirecting to /members');
  //
  //       return router.navigate({
  //         to: '/members',
  //         replace: true,
  //       });
  //     }
  //
  //     // Check the club_id is present in query params
  //     if (!club_id) {
  //       console.debug('club_id is missing in query params, redirecting to /asociate-ahora');
  //
  //       return router.navigate({
  //         to: '/asociate-ahora',
  //         replace: true,
  //       });
  //     }
  //   };
  //
  //   check();
  // }, [club_id, member?.MemberId, router]);

  const onSubmitted = useCallback(async () => {
    if (redirect) {
      window.location.replace(redirect);
      return Promise.resolve();
    }

    await navigate({
      to: '/miembros',
      replace: true,
    });
  }, [navigate, redirect]);

  return (
    <>
      <Helmet>
        <title>Asociate ahora | MovieClub</title>
      </Helmet>
      <div className="flex flex-col space-y-8">
        <SignUpStepper active={3} />
        <div>
          <>
            <h2 className=" text-xl font-bold">Medio de Pago</h2>
            <p className="text-xs text-gray-500 sm:text-sm">Agrega o tu medio de pago</p>
            <div className="m-auto mt-4 w-full rounded-sm border border-muted px-0">
              <PaymentMethodForm submitButtonText={'Finalizar'} onSubmitted={onSubmitted} />
            </div>
          </>
        </div>
      </div>
    </>
  );
}
