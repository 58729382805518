import { Loader2 } from 'lucide-react';
import { Button } from '@/components/ui/button.tsx';
import { cn } from '@/lib/utils.ts';

export type SubmitButtonProps = {
  label?: string;
  isPending?: boolean;
  className?: string;
};

export default function SubmitButton({
  label = 'Guardar',
  isPending = false,
  className = undefined,
}: SubmitButtonProps) {
  return (
    <Button type="submit" disabled={isPending} className={cn(className)}>
      {isPending && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
      {label}
    </Button>
  );
}
