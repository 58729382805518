import MoviePoster from '@/components/moviePoster';
import { Button } from '@/components/ui/button';
import { Skeleton } from '@/components/ui/skeleton';
import { Billboard } from '@/lib/cinepolis/movies/contracts';
import { useQuery } from '@tanstack/react-query';
import { MovieTag } from '@/lib/movies/constants.ts';
import { index } from '@/lib/cinepolis/complexes/api.ts';
import { useState } from 'react';

type Props = {
  endpoint: (params?: { complex: number }) => Promise<Billboard[]>;
  queryKey: string[];
};

export default function MoviesBillboard({ endpoint, queryKey }: Props) {
  const [selectedComplex, setSelectedComplex] = useState<number | null>(null);

  const {
    data: billboardPoster,
    isLoading,
    error,
    refetch,
  } = useQuery({
    queryKey: [...queryKey, selectedComplex],
    queryFn: () => endpoint(selectedComplex ? { complex: selectedComplex } : undefined),
  });

  const { data: complexes, isLoading: isLoadingComplexes } = useQuery({
    queryKey: ['complexes'],
    queryFn: () => index(),
  });

  if (error && !isLoading) {
    return (
      <>
        <p className="my-5 text-white">Tuvimos un problema al cargar las peliculas!</p>
        <Button variant="outline" onClick={() => refetch()}>
          Cargar Nuevamente
        </Button>
      </>
    );
  }

  return (
    <div className="flex flex-col space-y-8">
      <select
        className="select select-bordered w-full md:max-w-xs"
        disabled={isLoadingComplexes}
        defaultValue={selectedComplex ?? ''}
        onChange={(e) => {
          setSelectedComplex(e.currentTarget.value ? parseInt(e.currentTarget.value) : null);
        }}
      >
        <option value={''}>Todos los complejos</option>
        {complexes?.map((complex) => (
          <option key={complex.id} value={complex.id}>
            {complex.name}
          </option>
        ))}
      </select>

      <div className="movies-grid">
        {isLoading
          ? Array.from({ length: 5 }).map((_, index) => <Skeleton key={index} className="h-full min-h-56 w-full" />)
          : billboardPoster?.map((item) => (
              <MoviePoster
                key={item.id}
                img={item.poster_url}
                title={item.title_translated}
                link={`/peliculas/${item.id}` + (selectedComplex ? `?complex=${selectedComplex}` : '')}
                label={{ text: item.badge_string, className: MovieTag[item.badge_color] }}
              />
            ))}
      </div>
    </div>
  );
}
