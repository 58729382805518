import Brand from '@/components/brand';
import { Brands } from '@/lib/contants.ts';
import santanderImg from '@/assets/santander.jpg.jpeg';
import laNacionImg from '@/assets/la-nacion-c-1.jpg.jpeg';
import rioNegroImg from '@/assets/clubRN.png.png';
import movieClubImg from '@/assets/movie-club.jpg.jpeg';
import laMaquinitaImg from '@/assets/brands/la-maquinita.jpg';
import funescoopImg from '@/assets/brands/funescoop.jpg';
import clubMovistarImg from '@/assets/brands/club-movistar.jpg';

export default function BrandsGrid() {
  return (
    <div className="brands-grid mt-11 [&>*]:mb-10">
      <Brand bgColor="bg-[#F00F7E]" link={`/${Brands.MOVIECLUB}`} img={movieClubImg} name="Movie Club" />
      <Brand bgColor="bg-[#ED1F00]" link={`/${Brands.SANTANDER}`} img={santanderImg} name="Santander" />
      <Brand
        bgColor="bg-[#019df4]"
        link={`/${Brands.CLUB_MOVISTAR}`}
        img={clubMovistarImg}
        name="Club Movistar"
        offer="25%"
      />
      <Brand
        bgColor="bg-[#295B99]"
        link={`/${Brands.CLUB_LA_NACION}`}
        img={laNacionImg}
        name="Club La Nación"
        offer="20%"
      />
      <Brand
        bgColor="bg-[#FFFFFF]"
        link={`/${Brands.FUNESCOOP}`}
        img={funescoopImg}
        name="Funescoop"
        offer="50%"
        offerDetail="x 6 meses"
      />
      <Brand
        bgColor="bg-[#000]"
        link={`/${Brands.CLUB_RIO_NEGRO}`}
        img={rioNegroImg}
        name="Club Río Negro"
        offer="20%"
      />
      <Brand
        bgColor="bg-[#FFFFFF]"
        link={`/${Brands.LA_MAQUINITA}`}
        img={laMaquinitaImg}
        name="La Maquinita Co."
        offer="20%"
      />
    </div>
  );
}
