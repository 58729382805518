import { createFileRoute, Outlet } from '@tanstack/react-router';
import { useEffect, useState } from 'react';
import Loading from '@/components/common/loading.tsx';
import { useAuthState } from '@/lib/auth/hooks/useAuthState.ts';

export const Route = createFileRoute('/_checkAuth/_layout/_authenticated')({
  component: AuthenticatedRoute,
});

export function AuthenticatedRoute() {
  const [checked, setChecked] = useState(false);
  const { isAuthenticated } = useAuthState();
  const navigate = Route.useNavigate();

  useEffect(() => {
    const check = async () => {
      const location = window.location;

      console.debug(`${location} is protected, checking authentication...`);

      if (!isAuthenticated) {
        console.debug('Not authenticated, redirecting to login...');

        // Redirect to join page, so we ensure a membership
        return await navigate({
          to: '/login',
          search: {
            // Use the current location to power a redirect after login
            // (Do not use `router.state.resolvedLocation` as it can
            // potentially lag behind the actual current location)
            redirect: location.href,
          },
          replace: true,
        });
      }

      console.debug('Authenticated!');
    };

    check().finally(() => {
      setChecked(true);
    });
  }, [isAuthenticated, navigate]);

  if (!checked) {
    return <Loading />;
  }

  return <Outlet />;
}
