import * as Progress from '@radix-ui/react-progress';

export type TLoadingBarProps = {
  children?: React.ReactNode;
};

export default function LoadingBar({ children }: TLoadingBarProps) {
  return (
    <div className="flex h-full flex-col justify-center">
      <div className="mx-auto my-6 flex w-[100px] flex-col items-center space-y-2">
        {children}
        <Progress.Root className="relative h-1 w-full overflow-hidden rounded-full bg-gray-300">
          <Progress.Indicator className="progress-bar-value" />
        </Progress.Root>
      </div>
    </div>
  );
}
