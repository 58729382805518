import { createFileRoute } from '@tanstack/react-router';
import { useMember } from '@/lib/members/hooks/useMember.ts';
import Loading from '@/components/common/loading.tsx';
import { useEffect } from 'react';
import BrandsGrid from '@/lib/brands/components/brands-grid.tsx';
import { Helmet } from 'react-helmet-async';

export const Route = createFileRoute('/_checkAuth/_layout/asociate-ahora')({
  component: () => <Asociate />,
});

export default function Asociate() {
  const { member, isLoading } = useMember();
  const navigate = Route.useNavigate();

  // If the user is already a member redirect to profile
  useEffect(() => {
    const check = async () => {
      if (member?.MemberId) {
        await navigate({
          to: '/miembros',
          replace: true,
        });
      }
    };

    check().then();
  }, [member, navigate]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <Helmet>
        <title>Asociate ahora | MovieClub</title>
      </Helmet>
      <section className="w-full ">
        <h1 className="poppins-bold text-xl md:text-3xl [&>span]:text-primary">¡Asociate Ahora!</h1>
        <div className="mb-10 mt-4 h-[1px] w-full bg-border opacity-50"></div>
        <h1 className="poppins-bold text-xl md:text-3xl [&>span]:text-primary">
          Entrá al Club… y salí al Cine
          <br />
          Elegí <span>como</span> querés asociarte
        </h1>
        <BrandsGrid />
      </section>
    </>
  );
}
