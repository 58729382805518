import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useAuthState } from '@/lib/auth/hooks/useAuthState.ts';
import { Member } from '@/lib/members/member.ts';
import { getUser } from '@/lib/members';
import { useCallback } from 'react';

export type TUseMember = {
  isLoading: boolean;
  isPending: boolean;
  isFetched: boolean;
  member?: Member;
  reload: () => Promise<void>;
};

export function useMember(): TUseMember {
  const queryClient = useQueryClient();
  const { isAuthenticated, accessToken } = useAuthState();
  const {
    data: member,
    isPending,
    isFetched,
  } = useQuery<Member>({
    queryKey: ['me'],
    queryFn: async () => await getUser(),
    enabled: isAuthenticated && !!accessToken,
  });

  /**
   * Reload the user data
   */
  const reload = useCallback(async () => {
    await queryClient.invalidateQueries({
      queryKey: ['me'],
    });
  }, [queryClient]);

  return {
    isLoading: isAuthenticated && !!accessToken && isPending,
    isPending,
    isFetched,
    member,
    reload,
  };
}
