import { AxiosResponse } from 'axios';
import axiosInstance from '@/lib/api/client.ts';
import { Club } from '@/lib/clubs/contracts/club.ts';

export const getClubs = async () => {
  try {
    const response: AxiosResponse = await axiosInstance.get('/clubs');
    const responseData: Club[] = response.data;
    return responseData;
  } catch (error) {
    return Promise.reject(error);
  }
};
