import { Brand } from '@/lib/brands/contracts.ts';
import { Brands } from '@/lib/contants.ts';
import {
  CLUB_FUNESCOOP_EMPLEADOS_ID,
  CLUB_FUNESCOOP_ID,
  CLUB_LA_MAQUINITA_ID,
  CLUB_LA_NACION_ID,
  CLUB_MOVIECLUB_ID,
  CLUB_MOVISTAR_ID,
  CLUB_RIO_NEGRO_ID,
  CLUB_SANTANDER_ID,
} from '@/lib/config.ts';
import santanderImg from '@/assets/brands/santander.jpg';
import clubLNImg from '@/assets/brands/club_la_nacion.jpeg';
import clubRioNegroImg from '@/assets/brands/club_rio_negro.png';
import movieClubImg from '@/assets/brands/movie-club.jpg';
import laMaquinitaImg from '@/assets/brands/la-maquinita.jpg';
import funescoopImg from '@/assets/brands/funescoop.jpg';
import clubMovistarImg from '@/assets/brands/club-movistar.jpg';

export const brands: Record<string, Brand> = {
  [Brands.SANTANDER]: {
    id: +CLUB_SANTANDER_ID,
    slug: Brands.SANTANDER,
    pageTitle: 'Alianza Santander',
    title: 'Beneficios Extra para Tarjeta Santander',
    description: '¡Asociate Ahora! y sumá mas beneficios a tu suscripción Movie Club',
    img: santanderImg,
    price: '$9.500,00',
    items: [
      { title: '2 entradas', description: <p>Para ver la peli que quieras, ¡el día que quieras!</p> },
      {
        title: 'Descuentos en Candy',
        description: (
          <p>
            Anunciate como <strong>socio MovieClub</strong> antes de realizar tu compra para obtener el descuento.
          </p>
        ),
      },
      {
        title: 'Avant Premiere, Funciones Exclusivas',
        description: <p>Te invitamos a ser parte de las mejores pelis.</p>,
      },
      {
        title: 'Venta Anticipada',
        description: (
          <p>
            Tu lugar <strong>antes que nadie</strong> para esas pelis que todos quieren ver.
          </p>
        ),
      },
      {
        title: 'MoviePrice',
        description: (
          <p>
            Adquirí tu entrada a un precio exclusivo… <strong>¡todos los días!</strong>
          </p>
        ),
      },
    ],
    conditionsLink: '',
  },
  [Brands.CLUB_LA_NACION]: {
    id: +CLUB_LA_NACION_ID,
    slug: Brands.CLUB_LA_NACION,
    pageTitle: 'Alianza Club La Nación',
    title: 'Beneficios extra para socios Club La Nación',
    subTitle: '20% OFF en tu suscripción MovieClub por 1 año.',
    description: '¡Asociate Ahora! y sumá mas beneficios a tu suscripción Movie Club',
    img: clubLNImg,
    price: '$7.600,00',
    items: [
      { title: '2 entradas', description: <p>Para ver la peli que quieras, ¡el día que quieras!</p> },
      {
        title: 'Descuentos en Candy',
        description: (
          <p>
            Anunciate como <strong>socio MovieClub</strong> antes de realizar tu compra para obtener el descuento.
          </p>
        ),
      },
      {
        title: 'Avant Premiere, Funciones Exclusivas',
        description: <p>Te invitamos a ser parte de las mejores pelis.</p>,
      },
      {
        title: 'Venta Anticipada',
        description: (
          <p>
            Tu lugar <strong>antes que nadie</strong> para esas pelis que todos quieren ver.
          </p>
        ),
      },
      {
        title: 'MoviePrice',
        description: (
          <p>
            Adquirí tu entrada a un precio exclusivo… <strong>¡todos los días!</strong>
          </p>
        ),
      },
    ],
    conditionsLink: '',
    extraField: {
      label: 'Nro. de socio Club La Nación (Sólo números)',
    },
  },
  [Brands.CLUB_RIO_NEGRO]: {
    id: +CLUB_RIO_NEGRO_ID,
    slug: Brands.CLUB_RIO_NEGRO,
    pageTitle: 'Alianza Club Río Negro',
    title: 'Beneficios extra para socios Club La Nación',
    subTitle: '20% OFF en tu suscripción MovieClub por 1 año.',
    description: '¡Asociate Ahora! y sumá mas beneficios a tu suscripción Movie Club',
    img: clubRioNegroImg,
    price: '$7.600,00',
    items: [
      { title: '2 entradas', description: <p>Para ver la peli que quieras, ¡el día que quieras!</p> },
      {
        title: 'Descuentos en Candy',
        description: (
          <p>
            Anunciate como <strong>socio MovieClub</strong> antes de realizar tu compra para obtener el descuento.
          </p>
        ),
      },
      {
        title: 'Avant Premiere, Funciones Exclusivas',
        description: <p>Te invitamos a ser parte de las mejores pelis.</p>,
      },
      {
        title: 'Venta Anticipada',
        description: (
          <p>
            Tu lugar <strong>antes que nadie</strong> para esas pelis que todos quieren ver.
          </p>
        ),
      },
      {
        title: 'MoviePrice',
        description: (
          <p>
            Adquirí tu entrada a un precio exclusivo… <strong>¡todos los días!</strong>
          </p>
        ),
      },
    ],
    conditionsLink: '',
    extraField: {
      label: 'Nro. de tarjeta de Club Río Negro',
    },
  },
  [Brands.MOVIECLUB]: {
    id: +CLUB_MOVIECLUB_ID,
    slug: Brands.MOVIECLUB,
    pageTitle: 'Socio MovieClub',
    title: '¿Querés disfrutar de beneficios exclusivos?',
    description: '¡Asociate Ahora! Entrá a tu nuevo Club y salí al Cine',
    img: movieClubImg,
    price: '$9.500,00',
    items: [
      { title: '2 entradas', description: <p>Para ver la peli que quieras, ¡el día que quieras!</p> },
      {
        title: 'Descuentos en Candy',
        description: (
          <p>
            Anunciate como <strong>socio MovieClub</strong> antes de realizar tu compra para obtener el descuento.
          </p>
        ),
      },
      {
        title: 'Avant Premiere, Funciones Exclusivas',
        description: <p>Te invitamos a ser parte de las mejores pelis.</p>,
      },
      {
        title: 'Venta Anticipada',
        description: (
          <p>
            Tu lugar <strong>antes que nadie</strong> para esas pelis que todos quieren ver.
          </p>
        ),
      },
      {
        title: 'MoviePrice',
        description: (
          <p>
            Adquirí tu entrada a un precio exclusivo… <strong>¡todos los días!</strong>
          </p>
        ),
      },
    ],
    conditionsLink: '',
  },
  [Brands.FUNESCOOP]: {
    id: +CLUB_FUNESCOOP_ID,
    slug: Brands.FUNESCOOP,
    pageTitle: 'Alianza Funescoop',
    title: '¿Querés disfrutar de beneficios exclusivos?',
    description: '¡Asociate Ahora! Entrá a tu nuevo Club y salí al Cine',
    img: funescoopImg,
    price: '$4.750,00',
    items: [
      {
        title: 'Beneficios extra para clientes Funescoop',
        description: <p>2 meses gratis + 50% OFF en tu suscripción MovieClub por 6 meses.</p>,
      },
      { title: '2 entradas', description: <p>Para ver la peli que quieras, ¡el día que quieras!</p> },
      {
        title: 'Descuentos en Candy',
        description: (
          <p>
            Anunciate como <strong>socio MovieClub</strong> antes de realizar tu compra para obtener el descuento.
          </p>
        ),
      },
      {
        title: 'Avant Premiere, Funciones Exclusivas',
        description: <p>Te invitamos a ser parte de las mejores pelis.</p>,
      },
      {
        title: 'Venta Anticipada',
        description: (
          <p>
            Tu lugar <strong>antes que nadie</strong> para esas pelis que todos quieren ver.
          </p>
        ),
      },
      {
        title: 'MoviePrice',
        description: (
          <p>
            Adquirí tu entrada a un precio exclusivo… <strong>¡todos los días!</strong>
          </p>
        ),
      },
    ],
    conditionsLink: '',
  },
  [Brands.FUNESCOOP_EMPLEADOS]: {
    id: +CLUB_FUNESCOOP_EMPLEADOS_ID,
    slug: Brands.FUNESCOOP_EMPLEADOS,
    pageTitle: 'Movieclub Funescoop Empleados',
    title: '¿Querés disfrutar de beneficios exclusivos?',
    description: '¡Asociate Ahora! Entrá a tu nuevo Club y salí al Cine',
    img: funescoopImg,
    price: '$3.700,00',
    items: [
      {
        title: 'Beneficios extra para empleados Funescoop',
        description: <p>4 meses gratis + 50% OFF en tu suscripción MovieClub por 9 meses.</p>,
      },
      { title: '2 entradas', description: <p>Para ver la peli que quieras, ¡el día que quieras!</p> },
      {
        title: 'Descuentos en Candy',
        description: (
          <p>
            Anunciate como <strong>socio MovieClub</strong> antes de realizar tu compra para obtener el descuento.
          </p>
        ),
      },
      {
        title: 'Avant Premiere, Funciones Exclusivas',
        description: <p>Te invitamos a ser parte de las mejores pelis.</p>,
      },
      {
        title: 'Venta Anticipada',
        description: (
          <p>
            Tu lugar <strong>antes que nadie</strong> para esas pelis que todos quieren ver.
          </p>
        ),
      },
      {
        title: 'MoviePrice',
        description: (
          <p>
            Adquirí tu entrada a un precio exclusivo… <strong>¡todos los días!</strong>
          </p>
        ),
      },
    ],
    conditionsLink: '',
  },
  [Brands.LA_MAQUINITA]: {
    id: +CLUB_LA_MAQUINITA_ID,
    slug: Brands.LA_MAQUINITA,
    pageTitle: 'Alianza La Maquinita Co.',
    title: '¿Querés disfrutar de beneficios exclusivos?',
    description: '¡Asociate Ahora! Entrá a tu nuevo Club y salí al Cine',
    img: laMaquinitaImg,
    price: '$7.600,00',
    items: [
      {
        title: 'Beneficios extra para clientes La Maquinita',
        description: <p>1 mes gratis + 20% OFF en tu suscripción MovieClub.</p>,
      },
      { title: '2 entradas', description: <p>Para ver la peli que quieras, ¡el día que quieras!</p> },
      {
        title: 'Descuentos en Candy',
        description: (
          <p>
            Anunciate como <strong>socio MovieClub</strong> antes de realizar tu compra para obtener el descuento.
          </p>
        ),
      },
      {
        title: 'Avant Premiere, Funciones Exclusivas',
        description: <p>Te invitamos a ser parte de las mejores pelis.</p>,
      },
      {
        title: 'Venta Anticipada',
        description: (
          <p>
            Tu lugar <strong>antes que nadie</strong> para esas pelis que todos quieren ver.
          </p>
        ),
      },
      {
        title: 'MoviePrice',
        description: (
          <p>
            Adquirí tu entrada a un precio exclusivo… <strong>¡todos los días!</strong>
          </p>
        ),
      },
    ],
    conditionsLink: '',
  },
  [Brands.CLUB_MOVISTAR]: {
    id: +CLUB_MOVISTAR_ID,
    slug: Brands.CLUB_MOVISTAR,
    pageTitle: 'Club Movistar',
    title: '¿Querés disfrutar de beneficios exclusivos?',
    description: '¡Asociate Ahora! Entrá a tu nuevo Club y salí al Cine',
    img: clubMovistarImg,
    price: '$7.125,00',
    items: [
      {
        title: 'Beneficios extra para socios Club Movistar',
        description: <p>2 meses gratis + 25% OFF en tu suscripción MovieClub.</p>,
      },
      { title: '2 entradas', description: <p>Para ver la peli que quieras, ¡el día que quieras!</p> },
      {
        title: 'Descuentos en Candy',
        description: (
          <p>
            Anunciate como <strong>socio MovieClub</strong> antes de realizar tu compra para obtener el descuento.
          </p>
        ),
      },
      {
        title: 'Avant Premiere, Funciones Exclusivas',
        description: <p>Te invitamos a ser parte de las mejores pelis.</p>,
      },
      {
        title: 'Venta Anticipada',
        description: (
          <p>
            Tu lugar <strong>antes que nadie</strong> para esas pelis que todos quieren ver.
          </p>
        ),
      },
      {
        title: 'MoviePrice',
        description: (
          <p>
            Adquirí tu entrada a un precio exclusivo… <strong>¡todos los días!</strong>
          </p>
        ),
      },
    ],
    conditionsLink: '',
  },
};

export const getBrandBySlug = (slug: string): Brand | undefined => {
  return brands[slug];
};

export const getBrandById = (id: number): Brand | undefined => {
  return Object.values(brands).find((brand) => brand.id === id);
};
