import { cn } from '@/lib/utils';
import { Link } from '@tanstack/react-router';

type Props = {
  link: string;
  img: string;
  name: string;
  offer?: string;
  offerDetail?: string;
  bgColor?: string;
};

export default function Brand({ link, img, name, offer, offerDetail, bgColor }: Props) {
  return (
    <Link
      to={link}
      className="flex h-fit w-full min-w-[220px] flex-col items-center overflow-hidden rounded-sm border border-border shadow-md"
    >
      <div className={cn('flex h-[90px] w-full items-center justify-center', bgColor)}>
        <img className="h-full object-cover" src={img} alt={name} />
      </div>
      <div className="w-full py-3">
        <p className="poppins-bold w-full text-center text-sm">{name}</p>
        {offer && (
          <p className="poppins-bold w-full text-center text-xl">
            <span className="text-primary">{offer}</span>
            <span> Off</span>
            {offerDetail && <span className="text-sm"> {offerDetail}</span>}
          </p>
        )}
      </div>
    </Link>
  );
}
