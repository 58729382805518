import { Skeleton } from '@/components/ui/skeleton';
import { cn } from '@/lib/utils';
import LoadingBar from '@/components/common/loading-bar.tsx';

export default function Loading() {
  return (
    <LoadingBar>
      <img className="w-auto object-contain" src="/img/logo_header-2.png" alt="logo" />
    </LoadingBar>
  );
}

export function LoadingDots({ className }: { className?: string }) {
  return (
    <div className="ml-2 flex h-4 items-end gap-1">
      <Skeleton className={cn('h-[3px] w-[3px] rounded-full bg-slate-50', className)} />
      <Skeleton className={cn('h-[3px] w-[3px] rounded-full bg-slate-50', className)} />
      <Skeleton className={cn('h-[3px] w-[3px] rounded-full bg-slate-50', className)} />
    </div>
  );
}
