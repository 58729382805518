import { Suspense } from 'react';
import Loading from '@/components/common/loading.tsx';
import { RouterProvider } from '@tanstack/react-router';
import { router } from '@/router.ts';

export default function InnerApp() {
  return (
    <Suspense fallback={<Loading />}>
      <RouterProvider router={router} />
    </Suspense>
  );
}
