import { ShowtimeFilters } from '@/lib/cinepolis/showtimes/contracts.ts';
import { compact } from 'lodash';

export function toQueryParams(filters: ShowtimeFilters) {
  return {
    screens: compact(filters.screens).length ? filters.screens.join(',') : undefined,
    formats: compact(filters.formats).length ? filters.formats.join(',') : undefined,
    versions: compact(filters.versions).length ? filters.versions.join(',') : undefined,
    dates: compact(filters.dates).length > 0 ? filters.dates.join(',') : undefined,
  };
}
