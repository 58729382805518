import { createFileRoute, Outlet } from '@tanstack/react-router';
import { useAuth0 } from '@auth0/auth0-react';
import { useAuthState } from '@/lib/auth/hooks/useAuthState.ts';
import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getUser } from '@/lib/members';
import { toast } from 'sonner';
import Loading from '@/components/common/loading.tsx';

export const Route = createFileRoute('/_checkAuth')({
  component: () => <CheckAuthRoute />,
});

function CheckAuthRoute() {
  // const [initialized, setInitialized] = React.useState(false);
  const { isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const { accessToken, setAccessToken, cleanSession } = useAuthState();
  const [, /* state */ setState] = useState();
  const { isPending: memberIsPending, isFetched: memberIsFetched } = useQuery({
    queryKey: ['me'],
    queryFn: async () => {
      console.debug('Getting user...');

      try {
        const member = await getUser();

        const name = member.FirstName ? `${member.FirstName} ${member.LastName}` : member.Email;

        console.debug(`Welcome, ${name}!`);

        // If not in production, log the user
        if (process.env.NODE_ENV !== 'production') {
          console.debug('Member data:', member);
        }

        return member;
      } catch (e) {
        setState(() => {
          throw e;
        });
      }

      return null;
    },
    enabled: isAuthenticated && !!accessToken,
  });

  // Wait for auth0 to initialize
  useEffect(() => {
    if (isLoading) {
      console.debug('Waiting for Auth0...');
      return;
    }

    console.debug('User is authenticated:', isAuthenticated);

    // Set the token for auth context provider
    if (isAuthenticated) {
      const checkAuth = async () => {
        console.debug('Checking auth...');
        const accessToken = await getAccessTokenSilently();

        console.debug('Setting access token...');

        setAccessToken(accessToken);
      };

      checkAuth()
        .catch((e: unknown) => {
          console.error('Error during initialization:', e);

          // If something failed during auth checking, restore session and redirect home
          console.debug('Restoring session...');

          return cleanSession();
        })
        .finally(() => {
          console.debug('Auth checked!');
        });
    }
  }, [cleanSession, getAccessTokenSilently, isAuthenticated, isLoading, setAccessToken]);

  // Get error from query params
  useEffect(() => {
    const url = new URL(window.location.href);
    const error = url.searchParams.get('error');
    const errorDescription = url.searchParams.get('error_description');

    if (error) {
      console.error('Error:', error, errorDescription);

      setTimeout(() => {
        toast.error(error, {
          description: errorDescription,
        });
      }, 500);
    }
  }, []);

  const initialized = !isLoading && ((isAuthenticated && !memberIsPending && memberIsFetched) || !isAuthenticated);

  // Important: Do not remove this, it's required for the router to work.
  if (!initialized) {
    return <Loading />;
  }

  return <Outlet />;
}
