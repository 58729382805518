import { createFileRoute, Outlet } from '@tanstack/react-router';
import { useMember } from '@/lib/members/hooks/useMember.ts';
import Loading from '@/components/common/loading.tsx';
import { useEffect, useState } from 'react';

/**
 * This route is responsible for redirecting to the profile page if it's not complete
 */
export const Route = createFileRoute('/_checkAuth/_layout/_authenticated/_member')({
  component: CheckIsMemberRoute,
});

export function CheckIsMemberRoute() {
  const [checked, setChecked] = useState(false);
  const { member } = useMember();
  const navigate = Route.useNavigate();

  useEffect(() => {
    const check = async () => {
      console.debug('Checking if the user is a member...');

      const hasMembership = !!member?.MemberId;

      if (!hasMembership) {
        console.debug('User is not a member, redirecting to join page...');

        // Redirect to join page, so we ensure a membership
        return await navigate({
          to: '/asociate',
          replace: true,
        });
      }

      console.debug('User is a member!');

      // Check member has payment method
      console.debug('Checking if the user has a payment method...');

      const hasPaymentMethod = !!member?.creditCard;

      if (!hasPaymentMethod) {
        console.debug('User does not have a payment method, redirecting to payment methods page...');

        // Redirect to payment methods page, so we ensure a payment method
        return await navigate({
          to: '/asociate/medio-de-pago',
          search: {
            redirect: location.href,
          },
        });
      }

      console.debug('User has a payment method!');
    };

    check().finally(() => {
      setChecked(true);
    });
  }, [member, navigate]);

  if (!checked) {
    return <Loading />;
  }

  return <Outlet />;
}
