import Nav from '@/components/common/nav';
import Separator from '@/components/ui/separator.tsx';
import { createFileRoute, Outlet } from '@tanstack/react-router';
import { useMember } from '@/lib/members/hooks/useMember.ts';
import useMenuItems from '@/hooks/useMenuItems.tsx';
import useClubs from '@/lib/clubs/hooks/useClubs.ts';

export const Route = createFileRoute('/_checkAuth/_layout/_authenticated/_member/_layout')({
  component: LayoutComponent,
});

function LayoutComponent() {
  const { member } = useMember();
  const { navList } = useMenuItems();
  const { currentClub } = useClubs();

  return (
    <>
      <div>
        <div className="flex flex-col space-y-2 font-bold text-secondary lg:flex-row lg:items-center lg:justify-between">
          <div>
            <h2 className="text-md mr-2 sm:text-xl">
              Hola, {member?.FirstName} {member?.LastName}
            </h2>
            <p className="text-xs font-black text-secondary sm:text-xs">{member?.Email}</p>
          </div>
          {!!member?.loyaltyCardNumber && (
            <div className="text-md bg-muted p-2 text-center leading-tight text-card-foreground sm:text-xl lg:rounded-sm lg:border lg:border-muted">
              {member?.loyaltyCardNumber ? `Credencial N° ${member?.loyaltyCardNumber}` : 'Sin credencial'}
            </div>
          )}
        </div>
        <p className="py-5 text-xs md:text-base">Administra tu suscripción a {currentClub?.name ?? 'MovieClub'}</p>
        <Separator orientation="horizontal" />
      </div>
      <div className="flex flex-col gap-2 md:flex-row md:gap-6 md:pt-6">
        <Nav className="w-full md:w-96" items={navList} />
        <div className="mt-5 w-full md:mt-0">
          <Outlet />
        </div>
      </div>
    </>
  );
}
