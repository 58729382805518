import { ListItemsType } from '@/components/ui/header/header.types.ts';
import { useMemo } from 'react';
import { cn } from '@/lib/utils.ts';
import { desktop, mobile } from '@/components/ui/header/navItem.tsx';
import { useAuthState } from '@/lib/auth/hooks/useAuthState.ts';
import { Link } from '@tanstack/react-router';
import { NavList } from '@/components/common/nav/types.ts';
import { useMember } from '@/lib/members/hooks/useMember.ts';
import { AlertTriangleIcon } from 'lucide-react';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip.tsx';
import igIconImg from '@/assets/ig-icon.jpg';
import fbIconImg from '@/assets/fb-icon.jpg';
import useClubs from '@/lib/clubs/hooks/useClubs.ts';

export default function useMenuItems() {
  const { signup, login, logout, isAuthenticated } = useAuthState();
  const { member, isLoading: isLoadingMember } = useMember();
  const { currentClub, isLoading: isLoadingClubs } = useClubs();

  const loginItem: ListItemsType = useMemo(() => {
    if (isAuthenticated) {
      return (
        <div
          key={2}
          onClick={() => logout()}
          className={cn('cursor-pointer pr-5 text-sm font-bold text-secondary transition-all duration-500 ease-in-out')}
        >
          Cerrar Sesión
        </div>
      );
    }

    return (
      <li
        key={2}
        onClick={() => login()}
        className={cn('cursor-pointer pr-5 text-sm font-bold text-secondary transition-all duration-500 ease-in-out')}
      >
        Iniciar Sesión
      </li>
    );
  }, [isAuthenticated, login, logout]);

  const signUpItem: ListItemsType = useMemo(() => {
    if (isAuthenticated || isAuthenticated) {
      return null;
    }

    return (
      <li
        key={1}
        onClick={() =>
          signup({
            returnTo: '/asociate',
          })
        }
        className={cn(
          'cursor-pointer pr-5 text-sm font-bold text-secondary transition-all duration-500 ease-in-out',
          mobile,
          desktop,
          'lg:pr-4',
        )}
      >
        Asociate Ahora
      </li>
    );
  }, [isAuthenticated, signup]);

  const itemsList: ListItemsType[] = useMemo(
    () => [
      ...(isAuthenticated
        ? [
            <div
              key="profile"
              className={cn('text-sm font-bold text-secondary transition-all duration-500 ease-in-out')}
            >
              <Link to="/miembros">Mi Cuenta</Link>
            </div>,
          ]
        : []),
      signUpItem,
      loginItem,
      ...(!isAuthenticated
        ? [
            {
              id: 3,
              label: (
                <img
                  className="mr-2 flex h-auto w-6 justify-center transition-all duration-300 hover:opacity-60"
                  src={igIconImg}
                  alt="instagram"
                />
              ),
              link: 'https://www.instagram.com/MovieClubAr/',
              lgVisible: true,
            },
            {
              id: 4,
              label: (
                <img
                  className="mr-4 flex h-auto w-6 justify-center transition-all duration-300 hover:opacity-60"
                  src={fbIconImg}
                  alt="facebook"
                />
              ),
              link: 'https://www.facebook.com/MovieClubAr',
              lgVisible: true,
            },
          ]
        : []),
    ],
    [isAuthenticated, loginItem, signUpItem],
  );

  const navList = useMemo<NavList[]>(() => {
    const requiresCreditCard = !currentClub || currentClub.requiresCreditCard;

    const list: NavList[] = [
      { link: '/miembros', label: 'Mis puntos y beneficios' },
      { link: '/miembros/perfil', label: 'Mis Datos' },
      { link: '/miembros/historial-de-recompensas', label: 'Historial de recompensas' },
    ];

    if (requiresCreditCard) {
      list.push({
        link: '/miembros/medio-de-pago',
        label: 'Medio de pago',
        icon:
          !!member && !member.creditCard ? (
            <TooltipProvider delayDuration={250}>
              <Tooltip>
                <TooltipTrigger asChild>
                  <AlertTriangleIcon className="h-6 w-6" />
                </TooltipTrigger>
                <TooltipContent>
                  <p>Por favor, complete su medio de pago para poder disfrutar de los beneficios de MovieClub</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          ) : null,
      });
    }

    return list;
  }, [currentClub, member]);

  return {
    isLoading: isLoadingMember || isLoadingClubs,
    itemsList,
    navList,
  };
}
