import { FC, ReactElement } from 'react';
import { Button } from '@/components/ui/button';
import InstagramIcon from '@/assets/instagram.svg?react';
import FacebookIcon from '@/assets/facebook.svg?react';
import { Link } from '@tanstack/react-router';
import cinepolisImg from '@/assets/cinepolis2022.png';
import dataFiscalImg from '@/assets/data-fiscal-mc.png';
import { BASES_AND_CONDITIONS, CONTESTS_URL, TERMS_AND_CONDITIONS } from '@/lib/config.ts';

const Footer: FC = (): ReactElement => {
  return (
    <footer className="bg-neutral-800 pt-4 text-white">
      <div className="site-container">
        <div>
          <div>
            <a href="https://cinepolis.com.ar">
              <img loading="lazy" decoding="async" width="91" height="50" src={cinepolisImg} alt="" />
              <div className="top-1 mb-4 w-14 border border-white" />
            </a>
          </div>

          <div className="gap-[5%] sm:grid sm:grid-cols-2 lg:grid-cols-4 ">
            <div className="[&>p]:pb-4 [&>p]:text-xs">
              <a className="!text-base font-bold" href="/">
                MovieClub
              </a>
              <p>La forma más conveniente de ir al cine</p>

              <div className="flex flex-col [&>a]:h-5">
                <Link to="/asociate-ahora">
                  <Button type="button" className="p-0" size="sm" variant="link">
                    ¡Asociate Ahora!
                  </Button>
                </Link>
                <Link to="/miembros">
                  <Button type="button" className="p-0" size="sm" variant="link">
                    Inicar Sesión
                  </Button>
                </Link>
                <a href={CONTESTS_URL} rel="noopener">
                  <Button className="p-0 text-white" size="sm" variant="link">
                    Concursos
                  </Button>
                </a>
              </div>
            </div>

            <div className="mt-8 sm:mt-0 [&>p]:pb-4">
              <p className="text-base font-bold">Info</p>
              <div className="flex flex-col [&>a]:h-5 [&>a]:text-white">
                <a href={TERMS_AND_CONDITIONS} target="_blank" rel="noopener">
                  <Button className="p-0 text-white" size="sm" variant="link">
                    Términos y Condiciones
                  </Button>
                </a>
                <a href={BASES_AND_CONDITIONS} target="_blank" rel="noopener">
                  <Button className="p-0 text-white" size="sm" variant="link">
                    Bases y Condiciones
                  </Button>
                </a>
                <a href="https://soporte.cinepolis.com.ar/support/home" target="_blank" rel="noopener">
                  <Button className="p-0 text-white" size="sm" variant="link">
                    Preguntas Frecuentes
                  </Button>
                </a>
                <a
                  href="https://www.buenosaires.gob.ar/defensaconsumidor/como-denunciar"
                  target="_blank"
                  rel="noopener"
                >
                  <Button className="p-0 text-white" size="sm" variant="link">
                    Denuncia Defensa al Consumidor
                  </Button>
                </a>
              </div>
            </div>

            <div className="mt-8 lg:mt-0 [&>p]:pb-4">
              <p className="text-base font-bold">Estamos Conectados</p>
              <div className="flex flex-col [&>a]:text-xs [&>a]:leading-5 [&>a]:text-white">
                <a href="https://soporte.cinepolis.com.ar/support/home" target="_blank" rel="noopener">
                  <Button className="p-0 text-white" size="sm" variant="link">
                    Contactanos
                  </Button>
                </a>
                <p className="text-xs">Seguinos en Redes Sociales</p>
              </div>
              <ul className="mt-4 flex">
                <li className="">
                  <a href="https://www.facebook.com/MovieClubAr/" title="Follow on Facebook" target="_blank">
                    <Button
                      className="mr-2 flex h-7 w-7 items-center justify-center bg-[#3b5998] p-1 hover:bg-[#3b5998] hover:bg-opacity-50"
                      size="icon"
                    >
                      <FacebookIcon className="h-full text-white" />
                    </Button>
                  </a>
                </li>
                <li className="">
                  <a href="https://www.facebook.com/MovieClubAr/" title="Follow on Facebook" target="_blank">
                    <Button
                      className="mr-2 flex h-7 w-7 items-center justify-center bg-[#ea2c59] p-1 hover:bg-[#ea2c59] hover:bg-opacity-50"
                      size="icon"
                    >
                      <InstagramIcon className="h-full text-white" />
                    </Button>
                  </a>
                </li>
              </ul>
            </div>

            <a href="http://qr.afip.gob.ar/?qr=lXSlVg6RKrpi5pUxvYruKg,," target="_blank">
              <img
                className="pt-8"
                loading="lazy"
                decoding="async"
                width="73"
                height="100"
                src={dataFiscalImg}
                alt=""
                sizes="(max-width: 73px) 100vw, 73px"
              />
            </a>
          </div>

          <div className="pt-8">
            <a href="https://soporte.cinepolis.com.ar/support/tickets/new" target="_blank">
              <Button size="sm" variant="outline-primary">
                Boton de Arrepentimiento
              </Button>
            </a>
          </div>

          <div className="py-6 pt-8 [&>p]:text-xs">
            <p>
              <strong>Cines adheridos Capital y GBA</strong>: Cinépolis Avellaneda, Cinépolis Pilar, Cinépolis Recoleta,
              Cinépolis Merlo, Cinépolis Luján y Cinépolis Houssay.
              <br />
              <strong>Cines adheridos Interior:</strong> Cinépolis Mendoza, Cinépolis Maipú, Cinépolis Neuquén,
              Cinépolis Rosario.
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
