/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as RegistroImport } from './routes/registro'
import { Route as LogoutImport } from './routes/logout'
import { Route as LoginImport } from './routes/login'
import { Route as CheckAuthImport } from './routes/_checkAuth'
import { Route as CheckAuthIndexImport } from './routes/_checkAuth/index'
import { Route as CheckAuthLayoutImport } from './routes/_checkAuth/_layout'
import { Route as CheckAuthLayoutAsociateAhoraImport } from './routes/_checkAuth/_layout/asociate-ahora'
import { Route as CheckAuthLayoutAuthenticatedImport } from './routes/_checkAuth/_layout/_authenticated'
import { Route as CheckAuthLayoutBrandSlugImport } from './routes/_checkAuth/_layout/$brandSlug'
import { Route as CheckAuthLayoutPeliculasMovieIdImport } from './routes/_checkAuth/_layout/peliculas/$movieId'
import { Route as CheckAuthLayoutAuthenticatedAsociateImport } from './routes/_checkAuth/_layout/_authenticated/asociate'
import { Route as CheckAuthLayoutAuthenticatedMemberImport } from './routes/_checkAuth/_layout/_authenticated/_member'
import { Route as CheckAuthLayoutAuthenticatedAsociateMedioDePagoImport } from './routes/_checkAuth/_layout/_authenticated/asociate_/medio-de-pago'
import { Route as CheckAuthLayoutAuthenticatedMemberLayoutImport } from './routes/_checkAuth/_layout/_authenticated/_member/_layout'
import { Route as CheckAuthLayoutAuthenticatedMemberLayoutMiembrosImport } from './routes/_checkAuth/_layout/_authenticated/_member/_layout/miembros'
import { Route as CheckAuthLayoutAuthenticatedMemberLayoutMiembrosPerfilImport } from './routes/_checkAuth/_layout/_authenticated/_member/_layout/miembros_/perfil'
import { Route as CheckAuthLayoutAuthenticatedMemberLayoutMiembrosMedioDePagoImport } from './routes/_checkAuth/_layout/_authenticated/_member/_layout/miembros_/medio-de-pago'
import { Route as CheckAuthLayoutAuthenticatedMemberLayoutMiembrosHistorialDeRecompensasImport } from './routes/_checkAuth/_layout/_authenticated/_member/_layout/miembros_/historial-de-recompensas'

// Create/Update Routes

const RegistroRoute = RegistroImport.update({
  id: '/registro',
  path: '/registro',
  getParentRoute: () => rootRoute,
} as any)

const LogoutRoute = LogoutImport.update({
  id: '/logout',
  path: '/logout',
  getParentRoute: () => rootRoute,
} as any)

const LoginRoute = LoginImport.update({
  id: '/login',
  path: '/login',
  getParentRoute: () => rootRoute,
} as any)

const CheckAuthRoute = CheckAuthImport.update({
  id: '/_checkAuth',
  getParentRoute: () => rootRoute,
} as any)

const CheckAuthIndexRoute = CheckAuthIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => CheckAuthRoute,
} as any)

const CheckAuthLayoutRoute = CheckAuthLayoutImport.update({
  id: '/_layout',
  getParentRoute: () => CheckAuthRoute,
} as any)

const CheckAuthLayoutAsociateAhoraRoute =
  CheckAuthLayoutAsociateAhoraImport.update({
    id: '/asociate-ahora',
    path: '/asociate-ahora',
    getParentRoute: () => CheckAuthLayoutRoute,
  } as any)

const CheckAuthLayoutAuthenticatedRoute =
  CheckAuthLayoutAuthenticatedImport.update({
    id: '/_authenticated',
    getParentRoute: () => CheckAuthLayoutRoute,
  } as any)

const CheckAuthLayoutBrandSlugRoute = CheckAuthLayoutBrandSlugImport.update({
  id: '/$brandSlug',
  path: '/$brandSlug',
  getParentRoute: () => CheckAuthLayoutRoute,
} as any)

const CheckAuthLayoutPeliculasMovieIdRoute =
  CheckAuthLayoutPeliculasMovieIdImport.update({
    id: '/peliculas/$movieId',
    path: '/peliculas/$movieId',
    getParentRoute: () => CheckAuthLayoutRoute,
  } as any)

const CheckAuthLayoutAuthenticatedAsociateRoute =
  CheckAuthLayoutAuthenticatedAsociateImport.update({
    id: '/asociate',
    path: '/asociate',
    getParentRoute: () => CheckAuthLayoutAuthenticatedRoute,
  } as any)

const CheckAuthLayoutAuthenticatedMemberRoute =
  CheckAuthLayoutAuthenticatedMemberImport.update({
    id: '/_member',
    getParentRoute: () => CheckAuthLayoutAuthenticatedRoute,
  } as any)

const CheckAuthLayoutAuthenticatedAsociateMedioDePagoRoute =
  CheckAuthLayoutAuthenticatedAsociateMedioDePagoImport.update({
    id: '/asociate_/medio-de-pago',
    path: '/asociate/medio-de-pago',
    getParentRoute: () => CheckAuthLayoutAuthenticatedRoute,
  } as any)

const CheckAuthLayoutAuthenticatedMemberLayoutRoute =
  CheckAuthLayoutAuthenticatedMemberLayoutImport.update({
    id: '/_layout',
    getParentRoute: () => CheckAuthLayoutAuthenticatedMemberRoute,
  } as any)

const CheckAuthLayoutAuthenticatedMemberLayoutMiembrosRoute =
  CheckAuthLayoutAuthenticatedMemberLayoutMiembrosImport.update({
    id: '/miembros',
    path: '/miembros',
    getParentRoute: () => CheckAuthLayoutAuthenticatedMemberLayoutRoute,
  } as any)

const CheckAuthLayoutAuthenticatedMemberLayoutMiembrosPerfilRoute =
  CheckAuthLayoutAuthenticatedMemberLayoutMiembrosPerfilImport.update({
    id: '/miembros_/perfil',
    path: '/miembros/perfil',
    getParentRoute: () => CheckAuthLayoutAuthenticatedMemberLayoutRoute,
  } as any)

const CheckAuthLayoutAuthenticatedMemberLayoutMiembrosMedioDePagoRoute =
  CheckAuthLayoutAuthenticatedMemberLayoutMiembrosMedioDePagoImport.update({
    id: '/miembros_/medio-de-pago',
    path: '/miembros/medio-de-pago',
    getParentRoute: () => CheckAuthLayoutAuthenticatedMemberLayoutRoute,
  } as any)

const CheckAuthLayoutAuthenticatedMemberLayoutMiembrosHistorialDeRecompensasRoute =
  CheckAuthLayoutAuthenticatedMemberLayoutMiembrosHistorialDeRecompensasImport.update(
    {
      id: '/miembros_/historial-de-recompensas',
      path: '/miembros/historial-de-recompensas',
      getParentRoute: () => CheckAuthLayoutAuthenticatedMemberLayoutRoute,
    } as any,
  )

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/_checkAuth': {
      id: '/_checkAuth'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof CheckAuthImport
      parentRoute: typeof rootRoute
    }
    '/login': {
      id: '/login'
      path: '/login'
      fullPath: '/login'
      preLoaderRoute: typeof LoginImport
      parentRoute: typeof rootRoute
    }
    '/logout': {
      id: '/logout'
      path: '/logout'
      fullPath: '/logout'
      preLoaderRoute: typeof LogoutImport
      parentRoute: typeof rootRoute
    }
    '/registro': {
      id: '/registro'
      path: '/registro'
      fullPath: '/registro'
      preLoaderRoute: typeof RegistroImport
      parentRoute: typeof rootRoute
    }
    '/_checkAuth/_layout': {
      id: '/_checkAuth/_layout'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof CheckAuthLayoutImport
      parentRoute: typeof CheckAuthImport
    }
    '/_checkAuth/': {
      id: '/_checkAuth/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof CheckAuthIndexImport
      parentRoute: typeof CheckAuthImport
    }
    '/_checkAuth/_layout/$brandSlug': {
      id: '/_checkAuth/_layout/$brandSlug'
      path: '/$brandSlug'
      fullPath: '/$brandSlug'
      preLoaderRoute: typeof CheckAuthLayoutBrandSlugImport
      parentRoute: typeof CheckAuthLayoutImport
    }
    '/_checkAuth/_layout/_authenticated': {
      id: '/_checkAuth/_layout/_authenticated'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof CheckAuthLayoutAuthenticatedImport
      parentRoute: typeof CheckAuthLayoutImport
    }
    '/_checkAuth/_layout/asociate-ahora': {
      id: '/_checkAuth/_layout/asociate-ahora'
      path: '/asociate-ahora'
      fullPath: '/asociate-ahora'
      preLoaderRoute: typeof CheckAuthLayoutAsociateAhoraImport
      parentRoute: typeof CheckAuthLayoutImport
    }
    '/_checkAuth/_layout/_authenticated/_member': {
      id: '/_checkAuth/_layout/_authenticated/_member'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof CheckAuthLayoutAuthenticatedMemberImport
      parentRoute: typeof CheckAuthLayoutAuthenticatedImport
    }
    '/_checkAuth/_layout/_authenticated/asociate': {
      id: '/_checkAuth/_layout/_authenticated/asociate'
      path: '/asociate'
      fullPath: '/asociate'
      preLoaderRoute: typeof CheckAuthLayoutAuthenticatedAsociateImport
      parentRoute: typeof CheckAuthLayoutAuthenticatedImport
    }
    '/_checkAuth/_layout/peliculas/$movieId': {
      id: '/_checkAuth/_layout/peliculas/$movieId'
      path: '/peliculas/$movieId'
      fullPath: '/peliculas/$movieId'
      preLoaderRoute: typeof CheckAuthLayoutPeliculasMovieIdImport
      parentRoute: typeof CheckAuthLayoutImport
    }
    '/_checkAuth/_layout/_authenticated/_member/_layout': {
      id: '/_checkAuth/_layout/_authenticated/_member/_layout'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof CheckAuthLayoutAuthenticatedMemberLayoutImport
      parentRoute: typeof CheckAuthLayoutAuthenticatedMemberImport
    }
    '/_checkAuth/_layout/_authenticated/asociate_/medio-de-pago': {
      id: '/_checkAuth/_layout/_authenticated/asociate_/medio-de-pago'
      path: '/asociate/medio-de-pago'
      fullPath: '/asociate/medio-de-pago'
      preLoaderRoute: typeof CheckAuthLayoutAuthenticatedAsociateMedioDePagoImport
      parentRoute: typeof CheckAuthLayoutAuthenticatedImport
    }
    '/_checkAuth/_layout/_authenticated/_member/_layout/miembros': {
      id: '/_checkAuth/_layout/_authenticated/_member/_layout/miembros'
      path: '/miembros'
      fullPath: '/miembros'
      preLoaderRoute: typeof CheckAuthLayoutAuthenticatedMemberLayoutMiembrosImport
      parentRoute: typeof CheckAuthLayoutAuthenticatedMemberLayoutImport
    }
    '/_checkAuth/_layout/_authenticated/_member/_layout/miembros_/historial-de-recompensas': {
      id: '/_checkAuth/_layout/_authenticated/_member/_layout/miembros_/historial-de-recompensas'
      path: '/miembros/historial-de-recompensas'
      fullPath: '/miembros/historial-de-recompensas'
      preLoaderRoute: typeof CheckAuthLayoutAuthenticatedMemberLayoutMiembrosHistorialDeRecompensasImport
      parentRoute: typeof CheckAuthLayoutAuthenticatedMemberLayoutImport
    }
    '/_checkAuth/_layout/_authenticated/_member/_layout/miembros_/medio-de-pago': {
      id: '/_checkAuth/_layout/_authenticated/_member/_layout/miembros_/medio-de-pago'
      path: '/miembros/medio-de-pago'
      fullPath: '/miembros/medio-de-pago'
      preLoaderRoute: typeof CheckAuthLayoutAuthenticatedMemberLayoutMiembrosMedioDePagoImport
      parentRoute: typeof CheckAuthLayoutAuthenticatedMemberLayoutImport
    }
    '/_checkAuth/_layout/_authenticated/_member/_layout/miembros_/perfil': {
      id: '/_checkAuth/_layout/_authenticated/_member/_layout/miembros_/perfil'
      path: '/miembros/perfil'
      fullPath: '/miembros/perfil'
      preLoaderRoute: typeof CheckAuthLayoutAuthenticatedMemberLayoutMiembrosPerfilImport
      parentRoute: typeof CheckAuthLayoutAuthenticatedMemberLayoutImport
    }
  }
}

// Create and export the route tree

interface CheckAuthLayoutAuthenticatedMemberLayoutRouteChildren {
  CheckAuthLayoutAuthenticatedMemberLayoutMiembrosRoute: typeof CheckAuthLayoutAuthenticatedMemberLayoutMiembrosRoute
  CheckAuthLayoutAuthenticatedMemberLayoutMiembrosHistorialDeRecompensasRoute: typeof CheckAuthLayoutAuthenticatedMemberLayoutMiembrosHistorialDeRecompensasRoute
  CheckAuthLayoutAuthenticatedMemberLayoutMiembrosMedioDePagoRoute: typeof CheckAuthLayoutAuthenticatedMemberLayoutMiembrosMedioDePagoRoute
  CheckAuthLayoutAuthenticatedMemberLayoutMiembrosPerfilRoute: typeof CheckAuthLayoutAuthenticatedMemberLayoutMiembrosPerfilRoute
}

const CheckAuthLayoutAuthenticatedMemberLayoutRouteChildren: CheckAuthLayoutAuthenticatedMemberLayoutRouteChildren =
  {
    CheckAuthLayoutAuthenticatedMemberLayoutMiembrosRoute:
      CheckAuthLayoutAuthenticatedMemberLayoutMiembrosRoute,
    CheckAuthLayoutAuthenticatedMemberLayoutMiembrosHistorialDeRecompensasRoute:
      CheckAuthLayoutAuthenticatedMemberLayoutMiembrosHistorialDeRecompensasRoute,
    CheckAuthLayoutAuthenticatedMemberLayoutMiembrosMedioDePagoRoute:
      CheckAuthLayoutAuthenticatedMemberLayoutMiembrosMedioDePagoRoute,
    CheckAuthLayoutAuthenticatedMemberLayoutMiembrosPerfilRoute:
      CheckAuthLayoutAuthenticatedMemberLayoutMiembrosPerfilRoute,
  }

const CheckAuthLayoutAuthenticatedMemberLayoutRouteWithChildren =
  CheckAuthLayoutAuthenticatedMemberLayoutRoute._addFileChildren(
    CheckAuthLayoutAuthenticatedMemberLayoutRouteChildren,
  )

interface CheckAuthLayoutAuthenticatedMemberRouteChildren {
  CheckAuthLayoutAuthenticatedMemberLayoutRoute: typeof CheckAuthLayoutAuthenticatedMemberLayoutRouteWithChildren
}

const CheckAuthLayoutAuthenticatedMemberRouteChildren: CheckAuthLayoutAuthenticatedMemberRouteChildren =
  {
    CheckAuthLayoutAuthenticatedMemberLayoutRoute:
      CheckAuthLayoutAuthenticatedMemberLayoutRouteWithChildren,
  }

const CheckAuthLayoutAuthenticatedMemberRouteWithChildren =
  CheckAuthLayoutAuthenticatedMemberRoute._addFileChildren(
    CheckAuthLayoutAuthenticatedMemberRouteChildren,
  )

interface CheckAuthLayoutAuthenticatedRouteChildren {
  CheckAuthLayoutAuthenticatedMemberRoute: typeof CheckAuthLayoutAuthenticatedMemberRouteWithChildren
  CheckAuthLayoutAuthenticatedAsociateRoute: typeof CheckAuthLayoutAuthenticatedAsociateRoute
  CheckAuthLayoutAuthenticatedAsociateMedioDePagoRoute: typeof CheckAuthLayoutAuthenticatedAsociateMedioDePagoRoute
}

const CheckAuthLayoutAuthenticatedRouteChildren: CheckAuthLayoutAuthenticatedRouteChildren =
  {
    CheckAuthLayoutAuthenticatedMemberRoute:
      CheckAuthLayoutAuthenticatedMemberRouteWithChildren,
    CheckAuthLayoutAuthenticatedAsociateRoute:
      CheckAuthLayoutAuthenticatedAsociateRoute,
    CheckAuthLayoutAuthenticatedAsociateMedioDePagoRoute:
      CheckAuthLayoutAuthenticatedAsociateMedioDePagoRoute,
  }

const CheckAuthLayoutAuthenticatedRouteWithChildren =
  CheckAuthLayoutAuthenticatedRoute._addFileChildren(
    CheckAuthLayoutAuthenticatedRouteChildren,
  )

interface CheckAuthLayoutRouteChildren {
  CheckAuthLayoutBrandSlugRoute: typeof CheckAuthLayoutBrandSlugRoute
  CheckAuthLayoutAuthenticatedRoute: typeof CheckAuthLayoutAuthenticatedRouteWithChildren
  CheckAuthLayoutAsociateAhoraRoute: typeof CheckAuthLayoutAsociateAhoraRoute
  CheckAuthLayoutPeliculasMovieIdRoute: typeof CheckAuthLayoutPeliculasMovieIdRoute
}

const CheckAuthLayoutRouteChildren: CheckAuthLayoutRouteChildren = {
  CheckAuthLayoutBrandSlugRoute: CheckAuthLayoutBrandSlugRoute,
  CheckAuthLayoutAuthenticatedRoute:
    CheckAuthLayoutAuthenticatedRouteWithChildren,
  CheckAuthLayoutAsociateAhoraRoute: CheckAuthLayoutAsociateAhoraRoute,
  CheckAuthLayoutPeliculasMovieIdRoute: CheckAuthLayoutPeliculasMovieIdRoute,
}

const CheckAuthLayoutRouteWithChildren = CheckAuthLayoutRoute._addFileChildren(
  CheckAuthLayoutRouteChildren,
)

interface CheckAuthRouteChildren {
  CheckAuthLayoutRoute: typeof CheckAuthLayoutRouteWithChildren
  CheckAuthIndexRoute: typeof CheckAuthIndexRoute
}

const CheckAuthRouteChildren: CheckAuthRouteChildren = {
  CheckAuthLayoutRoute: CheckAuthLayoutRouteWithChildren,
  CheckAuthIndexRoute: CheckAuthIndexRoute,
}

const CheckAuthRouteWithChildren = CheckAuthRoute._addFileChildren(
  CheckAuthRouteChildren,
)

export interface FileRoutesByFullPath {
  '': typeof CheckAuthLayoutAuthenticatedMemberLayoutRouteWithChildren
  '/login': typeof LoginRoute
  '/logout': typeof LogoutRoute
  '/registro': typeof RegistroRoute
  '/': typeof CheckAuthIndexRoute
  '/$brandSlug': typeof CheckAuthLayoutBrandSlugRoute
  '/asociate-ahora': typeof CheckAuthLayoutAsociateAhoraRoute
  '/asociate': typeof CheckAuthLayoutAuthenticatedAsociateRoute
  '/peliculas/$movieId': typeof CheckAuthLayoutPeliculasMovieIdRoute
  '/asociate/medio-de-pago': typeof CheckAuthLayoutAuthenticatedAsociateMedioDePagoRoute
  '/miembros': typeof CheckAuthLayoutAuthenticatedMemberLayoutMiembrosRoute
  '/miembros/historial-de-recompensas': typeof CheckAuthLayoutAuthenticatedMemberLayoutMiembrosHistorialDeRecompensasRoute
  '/miembros/medio-de-pago': typeof CheckAuthLayoutAuthenticatedMemberLayoutMiembrosMedioDePagoRoute
  '/miembros/perfil': typeof CheckAuthLayoutAuthenticatedMemberLayoutMiembrosPerfilRoute
}

export interface FileRoutesByTo {
  '/login': typeof LoginRoute
  '/logout': typeof LogoutRoute
  '/registro': typeof RegistroRoute
  '': typeof CheckAuthLayoutAuthenticatedMemberLayoutRouteWithChildren
  '/': typeof CheckAuthIndexRoute
  '/$brandSlug': typeof CheckAuthLayoutBrandSlugRoute
  '/asociate-ahora': typeof CheckAuthLayoutAsociateAhoraRoute
  '/asociate': typeof CheckAuthLayoutAuthenticatedAsociateRoute
  '/peliculas/$movieId': typeof CheckAuthLayoutPeliculasMovieIdRoute
  '/asociate/medio-de-pago': typeof CheckAuthLayoutAuthenticatedAsociateMedioDePagoRoute
  '/miembros': typeof CheckAuthLayoutAuthenticatedMemberLayoutMiembrosRoute
  '/miembros/historial-de-recompensas': typeof CheckAuthLayoutAuthenticatedMemberLayoutMiembrosHistorialDeRecompensasRoute
  '/miembros/medio-de-pago': typeof CheckAuthLayoutAuthenticatedMemberLayoutMiembrosMedioDePagoRoute
  '/miembros/perfil': typeof CheckAuthLayoutAuthenticatedMemberLayoutMiembrosPerfilRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/_checkAuth': typeof CheckAuthRouteWithChildren
  '/login': typeof LoginRoute
  '/logout': typeof LogoutRoute
  '/registro': typeof RegistroRoute
  '/_checkAuth/_layout': typeof CheckAuthLayoutRouteWithChildren
  '/_checkAuth/': typeof CheckAuthIndexRoute
  '/_checkAuth/_layout/$brandSlug': typeof CheckAuthLayoutBrandSlugRoute
  '/_checkAuth/_layout/_authenticated': typeof CheckAuthLayoutAuthenticatedRouteWithChildren
  '/_checkAuth/_layout/asociate-ahora': typeof CheckAuthLayoutAsociateAhoraRoute
  '/_checkAuth/_layout/_authenticated/_member': typeof CheckAuthLayoutAuthenticatedMemberRouteWithChildren
  '/_checkAuth/_layout/_authenticated/asociate': typeof CheckAuthLayoutAuthenticatedAsociateRoute
  '/_checkAuth/_layout/peliculas/$movieId': typeof CheckAuthLayoutPeliculasMovieIdRoute
  '/_checkAuth/_layout/_authenticated/_member/_layout': typeof CheckAuthLayoutAuthenticatedMemberLayoutRouteWithChildren
  '/_checkAuth/_layout/_authenticated/asociate_/medio-de-pago': typeof CheckAuthLayoutAuthenticatedAsociateMedioDePagoRoute
  '/_checkAuth/_layout/_authenticated/_member/_layout/miembros': typeof CheckAuthLayoutAuthenticatedMemberLayoutMiembrosRoute
  '/_checkAuth/_layout/_authenticated/_member/_layout/miembros_/historial-de-recompensas': typeof CheckAuthLayoutAuthenticatedMemberLayoutMiembrosHistorialDeRecompensasRoute
  '/_checkAuth/_layout/_authenticated/_member/_layout/miembros_/medio-de-pago': typeof CheckAuthLayoutAuthenticatedMemberLayoutMiembrosMedioDePagoRoute
  '/_checkAuth/_layout/_authenticated/_member/_layout/miembros_/perfil': typeof CheckAuthLayoutAuthenticatedMemberLayoutMiembrosPerfilRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | ''
    | '/login'
    | '/logout'
    | '/registro'
    | '/'
    | '/$brandSlug'
    | '/asociate-ahora'
    | '/asociate'
    | '/peliculas/$movieId'
    | '/asociate/medio-de-pago'
    | '/miembros'
    | '/miembros/historial-de-recompensas'
    | '/miembros/medio-de-pago'
    | '/miembros/perfil'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/login'
    | '/logout'
    | '/registro'
    | ''
    | '/'
    | '/$brandSlug'
    | '/asociate-ahora'
    | '/asociate'
    | '/peliculas/$movieId'
    | '/asociate/medio-de-pago'
    | '/miembros'
    | '/miembros/historial-de-recompensas'
    | '/miembros/medio-de-pago'
    | '/miembros/perfil'
  id:
    | '__root__'
    | '/_checkAuth'
    | '/login'
    | '/logout'
    | '/registro'
    | '/_checkAuth/_layout'
    | '/_checkAuth/'
    | '/_checkAuth/_layout/$brandSlug'
    | '/_checkAuth/_layout/_authenticated'
    | '/_checkAuth/_layout/asociate-ahora'
    | '/_checkAuth/_layout/_authenticated/_member'
    | '/_checkAuth/_layout/_authenticated/asociate'
    | '/_checkAuth/_layout/peliculas/$movieId'
    | '/_checkAuth/_layout/_authenticated/_member/_layout'
    | '/_checkAuth/_layout/_authenticated/asociate_/medio-de-pago'
    | '/_checkAuth/_layout/_authenticated/_member/_layout/miembros'
    | '/_checkAuth/_layout/_authenticated/_member/_layout/miembros_/historial-de-recompensas'
    | '/_checkAuth/_layout/_authenticated/_member/_layout/miembros_/medio-de-pago'
    | '/_checkAuth/_layout/_authenticated/_member/_layout/miembros_/perfil'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  CheckAuthRoute: typeof CheckAuthRouteWithChildren
  LoginRoute: typeof LoginRoute
  LogoutRoute: typeof LogoutRoute
  RegistroRoute: typeof RegistroRoute
}

const rootRouteChildren: RootRouteChildren = {
  CheckAuthRoute: CheckAuthRouteWithChildren,
  LoginRoute: LoginRoute,
  LogoutRoute: LogoutRoute,
  RegistroRoute: RegistroRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/_checkAuth",
        "/login",
        "/logout",
        "/registro"
      ]
    },
    "/_checkAuth": {
      "filePath": "_checkAuth.tsx",
      "children": [
        "/_checkAuth/_layout",
        "/_checkAuth/"
      ]
    },
    "/login": {
      "filePath": "login.tsx"
    },
    "/logout": {
      "filePath": "logout.tsx"
    },
    "/registro": {
      "filePath": "registro.tsx"
    },
    "/_checkAuth/_layout": {
      "filePath": "_checkAuth/_layout.tsx",
      "parent": "/_checkAuth",
      "children": [
        "/_checkAuth/_layout/$brandSlug",
        "/_checkAuth/_layout/_authenticated",
        "/_checkAuth/_layout/asociate-ahora",
        "/_checkAuth/_layout/peliculas/$movieId"
      ]
    },
    "/_checkAuth/": {
      "filePath": "_checkAuth/index.tsx",
      "parent": "/_checkAuth"
    },
    "/_checkAuth/_layout/$brandSlug": {
      "filePath": "_checkAuth/_layout/$brandSlug.tsx",
      "parent": "/_checkAuth/_layout"
    },
    "/_checkAuth/_layout/_authenticated": {
      "filePath": "_checkAuth/_layout/_authenticated.tsx",
      "parent": "/_checkAuth/_layout",
      "children": [
        "/_checkAuth/_layout/_authenticated/_member",
        "/_checkAuth/_layout/_authenticated/asociate",
        "/_checkAuth/_layout/_authenticated/asociate_/medio-de-pago"
      ]
    },
    "/_checkAuth/_layout/asociate-ahora": {
      "filePath": "_checkAuth/_layout/asociate-ahora.tsx",
      "parent": "/_checkAuth/_layout"
    },
    "/_checkAuth/_layout/_authenticated/_member": {
      "filePath": "_checkAuth/_layout/_authenticated/_member.tsx",
      "parent": "/_checkAuth/_layout/_authenticated",
      "children": [
        "/_checkAuth/_layout/_authenticated/_member/_layout"
      ]
    },
    "/_checkAuth/_layout/_authenticated/asociate": {
      "filePath": "_checkAuth/_layout/_authenticated/asociate.tsx",
      "parent": "/_checkAuth/_layout/_authenticated"
    },
    "/_checkAuth/_layout/peliculas/$movieId": {
      "filePath": "_checkAuth/_layout/peliculas/$movieId.tsx",
      "parent": "/_checkAuth/_layout"
    },
    "/_checkAuth/_layout/_authenticated/_member/_layout": {
      "filePath": "_checkAuth/_layout/_authenticated/_member/_layout.tsx",
      "parent": "/_checkAuth/_layout/_authenticated/_member",
      "children": [
        "/_checkAuth/_layout/_authenticated/_member/_layout/miembros",
        "/_checkAuth/_layout/_authenticated/_member/_layout/miembros_/historial-de-recompensas",
        "/_checkAuth/_layout/_authenticated/_member/_layout/miembros_/medio-de-pago",
        "/_checkAuth/_layout/_authenticated/_member/_layout/miembros_/perfil"
      ]
    },
    "/_checkAuth/_layout/_authenticated/asociate_/medio-de-pago": {
      "filePath": "_checkAuth/_layout/_authenticated/asociate_/medio-de-pago.tsx",
      "parent": "/_checkAuth/_layout/_authenticated"
    },
    "/_checkAuth/_layout/_authenticated/_member/_layout/miembros": {
      "filePath": "_checkAuth/_layout/_authenticated/_member/_layout/miembros.tsx",
      "parent": "/_checkAuth/_layout/_authenticated/_member/_layout"
    },
    "/_checkAuth/_layout/_authenticated/_member/_layout/miembros_/historial-de-recompensas": {
      "filePath": "_checkAuth/_layout/_authenticated/_member/_layout/miembros_/historial-de-recompensas.tsx",
      "parent": "/_checkAuth/_layout/_authenticated/_member/_layout"
    },
    "/_checkAuth/_layout/_authenticated/_member/_layout/miembros_/medio-de-pago": {
      "filePath": "_checkAuth/_layout/_authenticated/_member/_layout/miembros_/medio-de-pago.tsx",
      "parent": "/_checkAuth/_layout/_authenticated/_member/_layout"
    },
    "/_checkAuth/_layout/_authenticated/_member/_layout/miembros_/perfil": {
      "filePath": "_checkAuth/_layout/_authenticated/_member/_layout/miembros_/perfil.tsx",
      "parent": "/_checkAuth/_layout/_authenticated/_member/_layout"
    }
  }
}
ROUTE_MANIFEST_END */
