import { find } from 'lodash';

export const SCREENS = [
  {
    label: 'Classic',
    value: 'standard',
    title: 'Sala Tradicional',
    long: 'Classic',
  },
  {
    label: 'GC',
    value: 'gold-class',
    title: 'Sala Gold Class',
    long: 'Gold Class',
  },
  {
    label: '4D',
    value: '4d',
    title: 'Sala 4D',
    long: '4D',
  },
  {
    label: 'MS',
    value: 'monster',
    title: 'Sala Monster Screen',
    long: 'Monster Screen',
  },
];

export const FORMATS = [
  {
    label: '2D',
    value: '2D',
    title: '2D',
  },
  {
    label: '3D',
    value: '3D',
    title: '3D',
  },
];

export const VERSIONS = [
  {
    label: 'Subtitulado',
    value: 'SUBT',
    title: 'Subtitulado',
  },
  {
    label: 'Español',
    value: 'CAST',
    title: 'Español',
  },
];

export const EXTRA_ATTRIBUTES = [
  {
    label: 'Descriptiva',
    value: 'DESCRIP',
    title: 'Descriptiva',
    long: 'Descriptiva',
  },
  {
    label: 'MAYOR DEFINICIÓN HFR 48',
    value: 'HFR',
    title: 'HFR 48',
    long: 'MAYOR DEFINICIÓN HFR 48',
  },
];

export const VIEWS = [
  {
    label: 'Películas',
    value: 'movies',
    icon: 'fas fa-th',
  },
  {
    label: 'Horarios',
    value: 'showtimes',
    icon: 'far fa-clock',
  },
];

export class Types {
  static screen(value: string, prop = 'label') {
    return this._prop(SCREENS, value, prop);
  }

  static format(value: string, prop = 'label') {
    return this._prop(FORMATS, value, prop);
  }

  static version(value: string, prop = 'label') {
    return this._prop(VERSIONS, value, prop);
  }

  static extra(value: string, prop = 'label') {
    return this._prop(EXTRA_ATTRIBUTES, value, prop);
  }

  static _prop(source: Array<Record<string, string>>, value: string, prop = 'label') {
    const match = find(source, { value: value });
    return match?.[prop];
  }
}
