import { Member } from '@/lib/members/member.ts';
import { Button } from '@/components/ui/button.tsx';
import { Link } from '@tanstack/react-router';
import { Info, Loader2 } from 'lucide-react';
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert.tsx';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  requestVerification as requestVerificationFn,
  validateVerificationCode as validateVerificationCodeFn,
} from '@/lib/members';
import { Form, FormControl, FormField, FormItem, FormMessage } from '@/components/ui/form.tsx';
import { Input } from '@/components/ui/input.tsx';
import { z } from '@/lib/es-zod.ts';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { handleServerErrors } from '@/lib/forms.ts';
import { cn } from '@/lib/utils.ts';
import { toast } from 'sonner';
import { useEffect, useState } from 'react';

const formSchema = z.object({
  // Code must be a string with a length of 6 digits
  code: z
    .string()
    .length(6)
    .regex(/^\d{6}$/, 'El código de verificación debe tener 6 dígitos'),
});

type FormValues = z.infer<typeof formSchema>;

export type MemberVerificationProps = {
  member: Member;
};

export default function MemberVerification({ member }: MemberVerificationProps) {
  const [remainingSecondsForRetry, setRemainingSecondsForRetry] = useState<number>(0);

  const queryClient = useQueryClient();

  const { mutateAsync: requestVerification, isPending: isPendingRequestVerification } = useMutation({
    mutationKey: ['verify-phone'],
    mutationFn: async () => {
      await requestVerificationFn();

      // Reload the member data
      await queryClient.refetchQueries({
        queryKey: ['me'],
      });

      return null;
    },
  });

  const { mutateAsync: validateVerificationCode, isPending: isPendingValidateVerificationCode } = useMutation({
    mutationKey: ['verify-phone'],
    mutationFn: async (code: string) => {
      try {
        await validateVerificationCodeFn(code);
      } catch (error) {
        handleServerErrors<FormValues>(form, error);
        return false;
      }

      // Reload the member data
      await queryClient.refetchQueries({
        queryKey: ['me'],
      });

      // Notify the user
      toast.success('¡Tu teléfono ha sido verificado correctamente!');

      return true;
    },
  });

  const isPending = isPendingRequestVerification || isPendingValidateVerificationCode;

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      code: '',
    },
    disabled: isPending,
  });

  const onSubmit = async (values: FormValues) => {
    console.debug('Submitting verification code:', values.code);

    const success = await validateVerificationCode(values.code);

    if (success) {
      form.reset();
    }
  };

  const phoneNumberSlice = member.MobilePhone?.slice(-4);

  const verificationInProgress = !!member && member.verificationStatus === 'in_progress';

  // const remainingSecondsForRetry = Math.max(
  //   0,
  //   member.verificationRetryAt ? Math.ceil((new Date(member.verificationRetryAt).getTime() - Date.now()) / 1000) : 0,
  // );

  // Update the remaining seconds for retry
  useEffect(() => {
    const interval = setInterval(() => {
      const diff = member.verificationRetryAt
        ? Math.ceil((new Date(member.verificationRetryAt).getTime() - Date.now()) / 1000)
        : 0;
      setRemainingSecondsForRetry(Math.max(0, diff));
    }, 1000);

    return () => clearInterval(interval);
  }, [member.verificationRetryAt, remainingSecondsForRetry]);

  return (
    <>
      {verificationInProgress && (
        <Alert className="border-blue-500/50 bg-blue-500/10 text-blue-500 dark:border-blue-500 [&>svg]:text-blue-500 [&>svg~*]:pl-9">
          <Info className="h-6 w-6" />
          <AlertTitle>¡Código enviado!</AlertTitle>
          <AlertDescription>
            <p className="mb-2 mt-2">
              Hemos enviado un código de verificación al teléfono terminado en <strong>{phoneNumberSlice}</strong>. Por
              favor, ingrésalo en el campo a continuación para completar la verificación.
            </p>
            <div className="mt-3 flex flex-col items-center">
              <Form {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)} className="w-full">
                  <div className="flex w-full flex-col items-stretch lg:items-center">
                    <FormField
                      control={form.control}
                      name="code"
                      render={({ field }) => (
                        <FormItem>
                          <FormControl>
                            <Input
                              placeholder="Código"
                              className="w-full self-center text-foreground lg:max-w-32"
                              {...field}
                              maxLength={6}
                              onChange={(value) => {
                                // Keep only numbers
                                const numericValue = value.target.value.replace(/\D/g, '');
                                field.onChange(numericValue);
                              }}
                              autoFocus={true}
                              autoComplete="off"
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <Button type="submit" className="mt-2 w-full lg:max-w-32" disabled={isPending}>
                      {isPending && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
                      Enviar
                    </Button>
                  </div>
                </form>
              </Form>
            </div>
            <p className="mt-4 text-sm">
              ¿No recibiste el código? Espera unos momentos o{' '}
              <span
                role="button"
                className={cn('underline', {
                  'cursor-default': remainingSecondsForRetry > 0,
                  'cursor-pointer': remainingSecondsForRetry === 0 && !isPending,
                  'opacity-50': remainingSecondsForRetry > 0 || isPending,
                })}
                onClick={() => remainingSecondsForRetry === 0 && !isPending && requestVerification()}
              >
                reenvía el código{remainingSecondsForRetry > 0 && ` en ${remainingSecondsForRetry}s`}
              </span>
              .
            </p>
          </AlertDescription>
        </Alert>
      )}

      {!verificationInProgress && (
        <Alert className="border-blue-500/50 bg-blue-500/10 text-blue-500 dark:border-blue-500 [&>svg]:text-blue-500 [&>svg~*]:pl-9">
          <Info className="h-6 w-6" />
          <AlertTitle>¡Verifica tu teléfono y comienza a disfrutar de todos los beneficios!</AlertTitle>
          <AlertDescription>
            <p className="mb-2 mt-2">
              Para garantizar la seguridad de tu cuenta y brindarte la mejor experiencia, te pedimos que verifiques tu
              número de teléfono. Este proceso es rápido y sencillo, y te permitirá acceder a todas las funcionalidades
              exclusivas que hemos preparado para ti.
            </p>
            <p>
              Tu teléfono termina en <strong>{phoneNumberSlice}</strong>. Si no es correcto,{' '}
              <Link to="/miembros/perfil" className="underline">
                modifícalo aquí
              </Link>
              .
            </p>
            <div className="mt-3 flex flex-col items-center">
              <Button type="button" disabled={isPending} onClick={() => requestVerification()}>
                {isPending && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
                Verificar teléfono
              </Button>
            </div>
          </AlertDescription>
        </Alert>
      )}
    </>
  );
}
