export const MovieTag: Record<string, string> = {
  red: 'bg-red-500',
  pink: 'bg-primary',
  yellow: 'bg-yellow-500',
  green: 'bg-highlight',
  purple: 'bg-purple-500',
  orange: 'bg-orange-500',
  black: 'bg-black',
  violet: 'bg-violet-500',
  blue: 'bg-blue-500',
  'blue-sky': 'bg-blue-sky-500',
  lightblue: 'bg-lightblue-500',
  cyan: 'bg-cyan-500',
  'green-lightblue': 'bg-green-lightblue-500',
  grey: 'bg-grey-500',
};
