import { createFileRoute, useRouter } from '@tanstack/react-router';
import MemberProfileForm from '@/lib/members/components/profile-form.tsx';
import { Alert, AlertDescription } from '@/components/ui/alert.tsx';
import { TriangleAlertIcon } from 'lucide-react';
import { useEffect } from 'react';
import { useMember } from '@/lib/members/hooks/useMember.ts';
import LoadingBar from '@/components/common/loading-bar.tsx';
import { z } from 'zod';
import SignUpStepper from '@/lib/members/components/signup-stepper.tsx';
import { Helmet } from 'react-helmet-async';

const joinSearchSchema = z.object({
  club_id: z.number().optional(),
});

export const Route = createFileRoute('/_checkAuth/_layout/_authenticated/asociate')({
  validateSearch: joinSearchSchema,
  component: Join,
});

function Join() {
  const router = useRouter();
  const { club_id: clubId } = Route.useSearch();
  const { member, isLoading } = useMember();

  // Wait for user data to load. If user is already a member, redirect to the dashboard
  useEffect(() => {
    const check = async () => {
      if (member?.MemberId) {
        console.debug('User is already a member, redirecting to /miembros');

        return router.navigate({
          to: '/miembros',
          replace: true,
        });
      }
    };

    check();
  }, [member?.MemberId, router]);

  if (isLoading) {
    return <LoadingBar />;
  }

  return (
    <>
      <Helmet>
        <title>Asociate ahora | MovieClub</title>
      </Helmet>
      <div className="flex flex-col space-y-8">
        <SignUpStepper active={2} />
        <div>
          <MemberProfileForm
            key={`${clubId}-${member?.MemberId}`}
            member={member ?? undefined}
            clubId={clubId ? `${clubId}` : undefined}
            header={
              !!member?.MemberId && (
                <Alert>
                  <TriangleAlertIcon className="h-6 w-6" />
                  <AlertDescription>Por favor, complete su perfil para unirse al club.</AlertDescription>
                </Alert>
              )
            }
            onSubmitted={async () => {
              // Force reload to update the member data
              const url = new URL(!member?.MemberId ? `${window.location.origin}/asociate/medio-de-pago` : '/');
              window.location.replace(url.href);
            }}
          />
        </div>
      </div>
    </>
  );
}
