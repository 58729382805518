import { AppState } from '@auth0/auth0-react';
import { v4 as uuidv4 } from 'uuid';
import axiosInstance from '@/lib/api/client.ts';
import { CreateResetPasswordLinkResponse } from '@/lib/auth/types.ts';

/**
 * Register a redirect Auth0 state to be used after the user logs in
 * @param redirect
 */
export const registerRedirectState = (redirect?: string) => {
  const nonce = uuidv4();

  const state = redirect
    ? {
        [nonce]: {
          redirectTo: redirect,
        },
      }
    : {};

  try {
    window.localStorage.setItem('redirect_state', JSON.stringify(state));
  } catch (e) {
    console.error('Could not save redirect state', e);
  }

  return state;
};

/**
 * Validate a redirect Auth0 state and return the redirect URL
 * @param appState The app state from the Auth0 callback
 */
export const validateRedirectState = (appState: AppState | undefined): string | undefined => {
  try {
    const state = window.localStorage.getItem('redirect_state');

    if (!state) {
      console.warn('No redirect state found');
      return undefined;
    }

    const redirectState = JSON.parse(state);

    // App state should contain redirect state keys
    for (const key in appState) {
      if (key in redirectState) {
        return redirectState[key].redirectTo;
      }
    }

    console.warn('No matching redirect state found');
  } catch (e) {
    console.error('Could not validate redirect state', e);
    return undefined;
  }
};

/**
 * Create a reset password link
 */
export const createResetPasswordLink = async (): Promise<CreateResetPasswordLinkResponse> => {
  const { data } = await axiosInstance.post<CreateResetPasswordLinkResponse>('/auth/reset-password');
  return data;
};
