import moment from 'moment/min/moment-with-locales';

export class Dates {
  static name(date: string, short = false) {
    const today = moment();
    const parsedDate = moment(date, 'YYYY-MM-DD');

    if (parsedDate.startOf('day').diff(today.startOf('day'), 'days') === 0) {
      return 'hoy';
    } else if (!short && parsedDate.diff(today, 'days') === 1) {
      return 'mañana';
    }

    return parsedDate.diff(today, 'days') >= 7
      ? parsedDate.format(short ? 'DD/MM' : 'DD MMM')
      : parsedDate.format(short ? 'ddd' : 'dddd');
  }
}
