import { useMemo, useState } from 'react';
import { index as complexIndex } from '@/lib/cinepolis/complexes/api.ts';
import { aggregations as movieAggregations, showtimes as movieShowtimes } from '@/lib/cinepolis/movies/api.ts';
import { Movie } from '@/lib/cinepolis/movies/contracts.ts';
import { FORMATS, SCREENS, Types, VERSIONS } from '@/lib/cinepolis/Types.ts';
import { useQuery } from '@tanstack/react-query';
import {
  Showtime,
  ShowtimeFilters,
  ShowtimeOption,
  ShowtimeShowtime,
  ShowtimeType,
} from '@/lib/cinepolis/showtimes/contracts.ts';
import { toQueryParams } from '@/lib/cinepolis/showtimes/utils.ts';
import { Button } from '@/components/ui/button.tsx';
import { Dates } from '@/lib/cinepolis/Dates.ts';
import LoadingIndicator from '@/components/ui/loadingIndicator.tsx';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/components/ui/accordion.tsx';
import { Complex } from '@/lib/cinepolis/complexes/contracts.ts';
import { flatMap, map } from 'lodash';
import Showtimes from '@/lib/cinepolis/Showtimes.ts';
import { cn } from '@/lib/utils.ts';
import ShowtimeModal from '@/lib/movies/components/showtime-modal.tsx';
import { ScrollArea, ScrollBar } from '@/components/ui/scroll-area.tsx';

export type ShowtimeSelectorProps = {
  movie: Movie;
  initialDate?: string;
  initialComplexId?: number;
};

export type ShowtimeSelection = {
  movieId: number;
  complexId: number;
  showtime: ShowtimeShowtime;
  options?: ShowtimeOption[];
};

const initialShowtimeFiltersValue: ShowtimeFilters = {
  complex: [],
  screens: [],
  formats: [],
  versions: [],
  dates: [],
};

export default function ShowtimeSelector({ movie, initialDate, initialComplexId }: ShowtimeSelectorProps) {
  const [filters, setFilters] = useState<ShowtimeFilters>({
    ...initialShowtimeFiltersValue,
    dates: initialDate ? [initialDate] : [],
    complex: initialComplexId ? [initialComplexId] : [],
  });
  const [selectedShowtime, setSelectedShowtime] = useState<ShowtimeSelection | null>(null);
  const [expandedComplexes, setExpandedComplexes] = useState<string[]>(initialComplexId ? [`${initialComplexId}`] : []);

  const queryFilters = useMemo(() => {
    return toQueryParams(filters);
  }, [filters]);

  const queryFiltersKey = useMemo<string>(() => {
    return JSON.stringify(queryFilters);
  }, [queryFilters]);

  const { data: complexes, isPending: isComplexesPending } = useQuery({
    queryKey: ['complexes', 'index'],
    queryFn: () => {
      return complexIndex();
    },
  });

  const { data: aggregations, isPending: isAggregationsPending } = useQuery({
    queryKey: ['aggregations', movie.id],
    queryFn: () => {
      return movieAggregations(movie.id);
    },
  });

  const { data: showtimes, isPending: isShowtimesPending } = useQuery({
    queryKey: ['showtimes', movie.id, queryFilters],
    queryFn: () => {
      return movieShowtimes(movie.id, queryFilters);
    },
    enabled: !!aggregations,
  });

  const availableComplexes = useMemo(() => {
    return (
      complexes?.filter((complex) => {
        return showtimes?.data.some((showtime) => showtime.complex_id === complex.id);
      }) ?? []
    );
  }, [complexes, showtimes]);

  // When aggregations are loaded, set the date filter to the first date if not set
  useMemo(() => {
    if (aggregations) {
      setFilters((filters) => ({
        ...filters,
        dates: filters.dates.length ? filters.dates : [aggregations.dates[0]],
      }));
    }
  }, [aggregations]);

  const ready = !isComplexesPending && !isAggregationsPending;

  if (!ready) {
    return (
      <div className="flex flex-row justify-center p-6">
        <LoadingIndicator />
      </div>
    );
  }

  return (
    <div className="flex flex-col space-y-2">
      <h3 className="font-bold">ELEGIR PELÍCULA POR:</h3>
      {/* Salas */}
      <div className="flex flex-row items-center">
        <div className="min-w-20">Salas</div>
        <div className="hidden lg:flex lg:items-center lg:space-x-1">
          {SCREENS.map((screen) => (
            <Button
              className={cn({
                'border-black/20 text-foreground': !filters.screens.includes(screen.value),
              })}
              type="button"
              variant={filters.screens.includes(screen.value) ? 'default' : 'outline'}
              key={screen.value}
              value={screen.value}
              aria-label={aggregations?.screens.includes(screen.value) ? screen.title : 'Sala no disponible'}
              disabled={!aggregations?.screens.includes(screen.value)}
              onClick={() => {
                setFilters((filters) => ({ ...filters, screens: screen.value ? [screen.value] : [] }));
              }}
            >
              <span>{screen.label}</span>
            </Button>
          ))}
        </div>
        <div className="w-full lg:hidden">
          <select
            className="select select-bordered w-full"
            key="screen-select-mobile"
            value={filters.screens[0] ?? ''}
            onChange={(e) => {
              const value = e.target.value;
              setFilters((filters) => ({ ...filters, screens: value ? [value] : [] }));
            }}
          >
            <option value="" disabled>
              Selecciona una sala...
            </option>
            {SCREENS.map((screen) => (
              <option
                className="capitalize"
                key={screen.value}
                value={screen.value}
                disabled={!aggregations?.screens.includes(screen.value)}
              >
                {screen.label}
              </option>
            ))}
          </select>
        </div>
        <span className="flex-1"></span>
        <Button
          type="button"
          variant="link"
          onClick={() => {
            setFilters((filters) => ({ ...filters, screens: [] }));
          }}
        >
          Todos
        </Button>
      </div>
      {/* Formato */}
      <div className="flex flex-row items-center">
        <div className="min-w-20">Formato</div>
        <div className="hidden lg:flex lg:items-center lg:space-x-1">
          {FORMATS.map((format) => (
            <Button
              className={cn({
                'border-black/20 text-foreground': !filters.formats.includes(format.value),
              })}
              type="button"
              variant={filters.formats.includes(format.value) ? 'default' : 'outline'}
              key={format.value}
              value={format.value}
              aria-label={aggregations?.formats.includes(format.value) ? format.title : 'Sala no disponible'}
              disabled={!aggregations?.formats.includes(format.value)}
              onClick={() => {
                setFilters((filters) => ({ ...filters, formats: format.value ? [format.value] : [] }));
              }}
            >
              <span>{format.label}</span>
            </Button>
          ))}
        </div>
        <div className="w-full lg:hidden">
          <select
            className="select select-bordered w-full"
            key="format-select-mobile"
            value={filters.formats[0] ?? ''}
            onChange={(e) => {
              const value = e.target.value;
              setFilters((filters) => ({ ...filters, formats: value ? [value] : [] }));
            }}
          >
            <option value="" disabled>
              Selecciona un formato...
            </option>
            {FORMATS.map((format) => (
              <option
                className="capitalize"
                key={format.value}
                value={format.value}
                disabled={!aggregations?.formats.includes(format.value)}
              >
                {format.label}
              </option>
            ))}
          </select>
        </div>
        <span className="flex-1"></span>
        <Button
          type="button"
          variant="link"
          onClick={() => {
            setFilters((filters) => ({ ...filters, formats: [] }));
          }}
        >
          Todos
        </Button>
      </div>
      {/* Idioma */}
      <div className="flex flex-row items-center">
        <div className="min-w-20">Idioma</div>
        <div className="hidden lg:flex lg:items-center lg:space-x-1">
          {VERSIONS.map((version) => (
            <Button
              className={cn({
                'border-black/20 text-foreground': !filters.versions.includes(version.value),
              })}
              type="button"
              variant={filters.versions.includes(version.value) ? 'default' : 'outline'}
              key={version.value}
              value={version.value}
              aria-label={aggregations?.versions.includes(version.value) ? version.title : 'Sala no disponible'}
              disabled={!aggregations?.versions.includes(version.value)}
              onClick={() => {
                setFilters((filters) => ({ ...filters, versions: version.value ? [version.value] : [] }));
              }}
            >
              <span>{version.label}</span>
            </Button>
          ))}
        </div>
        <div className="w-full lg:hidden">
          <select
            className="select select-bordered w-full"
            key="version-select-mobile"
            value={filters.versions[0] ?? ''}
            onChange={(e) => {
              const value = e.target.value;
              setFilters((filters) => ({ ...filters, versions: value ? [value] : [] }));
            }}
          >
            <option value="" disabled>
              Selecciona un idioma...
            </option>
            {VERSIONS.map((version) => (
              <option
                className="capitalize"
                key={version.value}
                value={version.value}
                disabled={!aggregations?.versions.includes(version.value)}
              >
                {version.label}
              </option>
            ))}
          </select>
        </div>
        <span className="flex-1"></span>
        <Button
          type="button"
          variant="link"
          onClick={() => {
            setFilters((filters) => ({ ...filters, versions: [] }));
          }}
        >
          Todos
        </Button>
      </div>

      {/* Días */}
      <div className="hidden lg:flex lg:flex-row lg:items-center">
        <ScrollArea className="w-full">
          <div className="flex items-center space-x-1 pb-3">
            {aggregations?.dates.map((date) => (
              <Button
                className={cn({
                  'border-black/20 text-foreground': !filters.dates.includes(date),
                })}
                type="button"
                variant={filters.dates.includes(date) ? 'default' : 'outline'}
                key={date}
                value={date}
                aria-label={aggregations?.dates.includes(date) ? date : 'Sala no disponible'}
                disabled={!aggregations?.dates.includes(date)}
                onClick={() => {
                  setFilters((filters) => ({ ...filters, dates: date ? [date] : [] }));
                }}
              >
                <span className="capitalize">{Dates.name(date, false)}</span>
              </Button>
            ))}
          </div>
          <ScrollBar orientation="horizontal" />
        </ScrollArea>
      </div>

      <div className="w-full lg:hidden">
        <select
          className="select select-bordered w-full"
          key="date-select-mobile"
          value={filters.dates[0] ?? ''}
          onChange={(e) => {
            const value = e.target.value;
            setFilters((filters) => ({ ...filters, dates: value ? [value] : [] }));
          }}
        >
          <option value="" disabled>
            Selecciona una fecha...
          </option>
          {aggregations?.dates.map((date) => (
            <option className="capitalize" key={date} value={date}>
              {Dates.name(date, false)}
            </option>
          ))}
        </select>
      </div>

      {isShowtimesPending && (
        <div className="flex flex-row justify-center p-6">
          <LoadingIndicator />
        </div>
      )}

      {!isShowtimesPending && (
        <>
          <h3 className="mb-4 font-bold">SELECCIONÁ UN COMPLEJO:</h3>
          <div key={queryFiltersKey}>
            <Accordion
              type="multiple"
              className="w-full"
              value={expandedComplexes}
              onValueChange={setExpandedComplexes}
            >
              {availableComplexes.map((complex) => {
                return (
                  <AccordionItem key={complex.id} value={`${complex.id}`} className="mb-1">
                    <AccordionTrigger className="bg-primary px-4 text-primary-foreground">
                      {complex.long_name || complex.name}
                    </AccordionTrigger>
                    <AccordionContent>
                      <ComplexShowtimes
                        movieId={movie.id}
                        complex={complex}
                        showtimes={showtimes?.data ?? []}
                        onShowtimeSelected={(selection) => setSelectedShowtime(selection)}
                      />
                    </AccordionContent>
                  </AccordionItem>
                );
              })}
            </Accordion>
          </div>
        </>
      )}

      <div className="flex flex-col space-y-2 lg:flex-row lg:items-center lg:space-x-2 lg:space-y-0">
        <small className="flex items-center space-x-1">
          <span className="inline-block h-4 w-4 rounded bg-green-500"></span>
          <span>Ocupación menor al 50%</span>
        </small>
        <small className="flex items-center space-x-1">
          <span className="inline-block h-4 w-4 rounded bg-yellow-500"></span>
          <span>Ocupación mayor al 50%</span>
        </small>
        <small className="flex items-center space-x-1">
          <span className="inline-block h-4 w-4 rounded bg-red-500"></span>
          <span>Ocupación mayor al 85%</span>
        </small>
      </div>

      <ShowtimeModal
        key={selectedShowtime?.showtime.external_id}
        open={!!selectedShowtime}
        movieId={selectedShowtime?.movieId}
        complexId={selectedShowtime?.complexId}
        showtime={selectedShowtime?.showtime}
        options={selectedShowtime?.options}
        onOpenChange={(open) => {
          if (!open) {
            setSelectedShowtime(null);
          }
        }}
      />
    </div>
  );
}

function ComplexShowtimes({
  movieId,
  complex,
  showtimes,
  onShowtimeSelected,
}: {
  movieId: number;
  complex: Complex;
  showtimes: Showtime[];
  onShowtimeSelected?: (showtime: ShowtimeSelection) => void;
}) {
  const complexShowtimes = showtimes.find((showtime) => showtime.complex_id === complex.id);

  return (
    <MovieShowtimes
      movieId={movieId}
      complexId={complex.id}
      showtimeTypes={complexShowtimes?.types ?? []}
      onShowtimeSelected={onShowtimeSelected}
    />
  );
}

function MovieShowtimes({
  movieId,
  complexId,
  showtimeTypes,
  onShowtimeSelected,
}: {
  movieId: number;
  complexId: number;
  showtimeTypes: ShowtimeType[];
  onShowtimeSelected?: (selection: ShowtimeSelection) => void;
}) {
  const parsedShowtimes = useMemo(() => {
    return flatMap(showtimeTypes, (screen, screenIndex) => {
      return map(screen.options, (option, optionIndex) => {
        const extraScreenAttributes = (option.attributes || [])
          .map((attr) => Types.extra(attr, 'label'))
          .filter((attr) => !!attr);

        return {
          hash: `${screenIndex}-${optionIndex}`,
          screen: screen.type,
          version: option.version,
          format: option.format,
          showtimes: option.showtimes,
          attributes: option.attributes,
          extraScreenAttributes,
        };
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex flex-col space-y-4">
      {parsedShowtimes.map((data) => (
        <div className="mb-2" key={data.hash}>
          <div className="mb-2 bg-muted p-2">
            <div className="col-12">
              <div className="flex items-center space-x-2 uppercase">
                <span>{Types.screen(data.screen, 'long')}</span>
                <span>&bull;</span>
                <span>{Types.format(data.format)}</span>
                <span>&bull;</span>
                <span>{Types.version(data.version)}</span>
                {data.extraScreenAttributes.map((extra) => (
                  <span key={extra}>&bull; {extra}</span>
                ))}
              </div>
            </div>
          </div>

          <div className="flex flex-row flex-wrap items-center gap-1">
            {data.showtimes.map((showtime) => (
              <Button
                type="button"
                className={cn(`min-w-[60px] rounded p-2 text-white`, [Showtimes.showtimeClass(showtime)])}
                key={showtime.external_id}
                title={Showtimes.showtimeTooltip(showtime)}
                onClick={(e) => {
                  e.preventDefault();
                  if (onShowtimeSelected) {
                    onShowtimeSelected({
                      movieId,
                      complexId,
                      showtime,
                    });
                  }
                }}
              >
                {Showtimes.formatStartsAt(showtime)}
              </Button>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}
