import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import { ChevronUp } from "lucide-react";
import { useState } from "react";

type Props = {
    img: string;
    text: string;
    description: React.ReactElement;
}

export default function Benefit({ img, text, description }: Props) {
    const [showDetail, setShowDetail] = useState(false);
    return (
        <div className="flex flex-col items-center gap-2">
            <img className="w-[70px]" src={img} alt="benefit" />
            <p className="font-bold poppins-bold">{text}</p>
            <Button
                onClick={() => setShowDetail(!showDetail)}
                variant="outline-primary"
                className="w-full"
            >
                <p className="text-sm mr-3 text-primary">Ver Detalles</p>
                <ChevronUp size={15} className={cn("transition-all ease-in-out duration-500", showDetail ? "rotate-180" : "")} />
            </Button>
            <div className={cn("w-full p-5 border-2 overflow-hidden rounded-sm bg-white transition-all ease-in-out duration-500",
                showDetail ? "opacity-100 max-h-[280px] h-fit" : "opacity-0 max-h-0",
            )}>
                {description}
            </div>
        </div>
    );
}