import { createFileRoute } from '@tanstack/react-router';
import { PaymentMethodForm } from '@/components/payment-method/payment-method-form.tsx';
import { useCallback } from 'react';
import { z } from 'zod';
import { Helmet } from 'react-helmet-async';

const paymentMethodsSearchSchema = z.object({
  redirect: z.string().optional(),
});

export const Route = createFileRoute('/_checkAuth/_layout/_authenticated/_member/_layout/miembros_/medio-de-pago')({
  validateSearch: paymentMethodsSearchSchema,
  component: () => <Payment />,
});

function Payment() {
  const { redirect } = Route.useSearch();

  const onSubmitted = useCallback(async () => {
    if (redirect) {
      window.location.replace(redirect);
    }
    return Promise.resolve();
  }, [redirect]);

  return (
    <>
      <Helmet>
        <title>Medio de Pago | MovieClub</title>
      </Helmet>
      <h2 className=" text-xl font-bold">Medio de Pago</h2>
      <p className="text-xs text-gray-500 sm:text-sm">Agrega o modifica tu medio de pago</p>
      <div className="mt-4 w-full rounded-sm border border-muted px-0">
        <PaymentMethodForm onSubmitted={onSubmitted} />
      </div>
    </>
  );
}
