import axios from 'axios';
import { CINEPOLIS_API_URL } from '@/lib/config.ts';

const axiosInstance = axios.create({
  baseURL: CINEPOLIS_API_URL,
});

// Log Cinépolis API Errors
// TODO: Handle errors
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    console.error(error);
    return Promise.reject(error);
  },
);

export default axiosInstance;
