import LoadingIndicator from '@/components/ui/loadingIndicator';
import { useQuery } from '@tanstack/react-query';
import { createFileRoute, Link, notFound } from '@tanstack/react-router';
import { Button, buttonVariants } from '@/components/ui/button';
import { Checkbox } from '@/components/ui/checkbox';
import { useState } from 'react';
import { cn } from '@/lib/utils';
import { useAuthState } from '@/lib/auth/hooks/useAuthState.ts';
import { getBrandBySlug } from '@/lib/brands/api.tsx';
import { Helmet } from 'react-helmet-async';
import { TERMS_AND_CONDITIONS } from '@/lib/config.ts';

export const Route = createFileRoute('/_checkAuth/_layout/$brandSlug')({
  component: () => <BrandDetailPage />,
});

function BrandDetailPage() {
  const { brandSlug } = Route.useParams();
  const [termsChecked, setTermsChecked] = useState(false);
  const { isAuthenticated } = useAuthState();

  const { data: brand, isPending: isPendingBrand } = useQuery({
    queryKey: ['brand', brandSlug],
    queryFn: () => {
      const brand = getBrandBySlug(brandSlug);

      if (!brand) {
        throw notFound();
      }

      return brand ?? null;
    },
    throwOnError: true,
  });

  if (isPendingBrand || !brand) {
    return (
      <div className="flex items-center justify-center p-6">
        <LoadingIndicator />
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>{brand.pageTitle} | MovieClub</title>
      </Helmet>
      <div>
        <div className="flex flex-col items-center gap-5 text-secondary-hover lg:flex-row lg:items-end">
          <img className="max-h-20 w-auto rounded-lg shadow-md" src={brand.img} alt="" />
          <div>
            <p className="text-2xl font-bold">{brand.price}/mes</p>
            <p className="text-sm">Precio con descuento incluido.</p>
          </div>
        </div>
        <h2 className="mt-5 text-xl font-bold text-secondary-hover">{brand.title}</h2>
        {brand.subTitle && <p className="text-muted-foreground">{brand.subTitle}</p>}
        <div>
          {brand.items.map((item, index) => (
            <div key={index} className="mb-2 flex flex-col first:mt-5 [&>p]:text-sm">
              <p className="!text-lg font-bold text-primary">{item.title}</p>
              {item.description}
            </div>
          ))}
          <p className="pt-5 text-sm text-muted-foreground">Descuentos aplicable en la tarifa vigente.</p>
        </div>
        <h2 className="mb-2 mt-5 text-xl font-bold text-secondary-hover">{brand.description}</h2>

        <p className="w-2/3 text-sm text-muted-foreground">
          Para asociarse a MovieClub deberá completar los datos del formulario. Recibirá un mail a la direccion
          ingresada para activar su cuenta. Todos los datos son obligatorios.
        </p>

        <div className="my-5 h-[1px] w-full bg-border opacity-50"></div>

        <Link to={TERMS_AND_CONDITIONS} target="_blank" preload={false}>
          <Button className="p-0 text-sm" variant="link">
            Ver Términos y Condiciones
          </Button>
        </Link>

        <div className="flex items-center gap-2">
          <Checkbox checked={termsChecked} id="terms" onClick={() => setTermsChecked(!termsChecked)} />
          <label htmlFor="terms"> estoy de acuerdo con los términos y condiciones</label>
        </div>

        <div
          className={cn(
            'mt-5 h-0 overflow-hidden transition-all duration-500',
            termsChecked ? 'h-10 opacity-100' : 'h-0 opacity-0',
          )}
        >
          <Link
            className={cn('w-48', buttonVariants({ variant: 'secondary', radius: 'full' }))}
            to="/asociate"
            search={{
              club_id: +brand.id,
            }}
          >
            {isAuthenticated ? 'Continuar' : 'Iniciar Sesión'}
          </Link>
        </div>
      </div>
    </>
  );
}
