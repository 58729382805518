import { Card } from '@/components/ui/card';
import Footer from '@/components/ui/footer';
import Header from '@/components/ui/header';
import { createFileRoute, Outlet } from '@tanstack/react-router';

export const Route = createFileRoute('/_checkAuth/_layout')({
  component: LayoutComponent,
});

function LayoutComponent() {
  return (
    <>
      <Header className="z-50" />
      <main className="sm:site-container z-0">
        <section className="mx-5 pb-10 sm:m-0">
          <Card className="overflow-hidden rounded-sm border bg-background shadow-sm">
            <div className="p-5 pb-16 sm:p-10">
              <Outlet />
            </div>
          </Card>
        </section>
      </main>
      <Footer />
    </>
  );
}
